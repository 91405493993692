/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Snackbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import Header from '../../components/Header';
import Logo from '../../components/Logo';
import ApiPath from '../../constants/api';
import apiCall from '../../utils/api';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION
} from '../../constants/common';
import { getQueryStringValue } from '../../utils/common';
import styles from './styles';

const UnsubExt = ({ classes }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [unsub, setUnsub] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    navigate('/');
  };

  const handleUnsubscribe = () => {
    const payload = getQueryStringValue(location, 'prefInfo');

    setIsLoading(true);

    apiCall(`${ApiPath.unsubExt}`, API.METHOD.POST, payload).then(
      (response) => {
        setIsLoading(false);
        setIsSnackbarOpen(true);

        if (response?.status === API_STATUS_CODE.SUCCESS) {
          setSnackbarMessage(response?.data?.message);
          setUnsub(true);
        } else setSnackbarMessage('Failed to unsubscribe');
      }
    );
  };

  return (
    <Grid container>
      <title>Comunidad Spark: {t('link.unsubscribe')}</title>
      <Grid item>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isSnackbarOpen}
          message={snackbarMessage}
          autoHideDuration={AUTO_HIDE_DURATION}
          onClose={() => setIsSnackbarOpen(false)}
          ContentProps={{
            classes: {
              root: classes.snackbarRoot
            }
          }}
        />
      </Grid>
      <Grid item>
        <Header />
      </Grid>
      <Grid item container className={classes.root}>
        <Logo type="women-with-phone" />
        {unsub && (
          <Typography className={classes.content} style={{ marginTop: 64 }}>
            {t('unsubExt.success')}
          </Typography>
        )}
        {!unsub && (
          <>
            <p className={classes.header}>{t('unsubExt.header')}?</p>
            <Typography className={classes.content}>
              {t('unsubExt.confirmMsg')}
            </Typography>
            <Grid item className={classes.buttonHolder}>
              <Button
                id="cancel"
                className={classes.button}
                onClick={handleCancel}
                color="primary"
              >
                {t('link.cancel')}
              </Button>
              <Button
                id="confirm"
                variant="contained"
                className={classes.button}
                color="primary"
                type="submit"
                value="pageNotFound"
                onClick={handleUnsubscribe}
                disabled={isLoading}
              >
                {t('link.yes')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

UnsubExt.defaultProps = {
  classes: {}
};

UnsubExt.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(UnsubExt);
