import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { Check, Close } from '@mui/icons-material';
import { useWindowSize } from '../../hooks';
import styles from './styles';

const PasswordRulesCheck = ({ classes, pword }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const PASSWORD_RULES_MX = [
    {
      rule: () => new RegExp('^(?=.{8,30}$)').test(pword)
    },
    {
      rule: () => new RegExp('^(?=.*[0-9])').test(pword)
    },
    {
      rule: () => new RegExp('^(?=.*[A-Z])').test(pword)
    },
    {
      rule: () => new RegExp('^(?=.*[a-z])').test(pword)
    },
    {
      rule: () => new RegExp('^(?=.*[!@#$%^&*])').test(pword)
    },
    {
      rule: () => !new RegExp('^(?=(.)+\\1\\1\\1)').test(pword)
    }
  ];

  return (
    <>
      {width > 960 && (
        <div className={classes.passwordRuleTitle}>
          {t(`pwordRules.title`)}:
        </div>
      )}
      {PASSWORD_RULES_MX.map((item, index) => (
        <div key={index} className={classes.passwordRulesItem}>
          {item.rule(pword) ? (
            <Check className={`${classes.checkIcon} black`} />
          ) : (
            <Close className={`${classes.checkIcon} red`} />
          )}
          <p className={classes.cushion}>{t(`pwordRules.title${index + 1}`)}</p>
        </div>
      ))}
    </>
  );
};

export default withStyles(styles)(PasswordRulesCheck);
