const styles = (theme) => ({
  dialog: {
    maxWidth: 328,
    height: 303,
    borderRadius: 4,
    margin: 0,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 444,
      height: 327
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 560
    }
  },
  dialogContent: {
    margin: '0 16px',
    padding: 0,
    '& > p': {
      fontSize: 14,
      color: '#333333'
    },
    '& p:first-child': {
      fontWeight: 300,
      marginBottom: 24
    },
    '& p:last-child': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 0,
      marginTop: 12
    },
    [theme.breakpoints.up('sm')]: {
      '& p:first-child': {
        fontSize: 16,
        marginBottom: 32
      }
    },
    [theme.breakpoints.up('md')]: {
      margin: '0 30px 0 24px',
      '& p:first-child': {
        marginBottom: 30
      }
    }
  },
  dialogOkActions: {
    padding: '32px 16px 16px 0',
    [theme.breakpoints.up('sm')]: {
      padding: '30px 16px 32px 0'
    },
    [theme.breakpoints.up('md')]: {
      padding: '32px 30px 32px 0'
    }
  },
  dialogTitle: {
    height: 48,
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.15,
    color: '#333333',
    padding: '16px 16px 8px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '32px 16px 24px',
      height: 24
    },
    [theme.breakpoints.up('md')]: {
      padding: '32px 24px 24px'
    },
    '& h2': {
      height: 24,
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.15,
      color: '#414042'
    }
  },
  formControl: {
    width: 120,
    height: 54,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      width: 177,
      paddingRight: 16
    },
    [theme.breakpoints.up('md')]: {
      width: 215,
      paddingRight: 46
    }
  },
  dayField: {
    width: 60,
    height: 54,
    borderRadius: 4,
    [theme.breakpoints.up('sm')]: {
      width: 100,
      paddingRight: 16
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: 46
    }
  },
  yearField: {
    width: 80,
    height: 54,
    borderRadius: 4,
    [theme.breakpoints.up('sm')]: {
      width: 100
    }
  },
  save: {
    width: 99,
    height: 40,
    padding: '10px 35px',
    borderRadius: 4,
    backgroundColor: '#0071ce',
    textTransform: 'capitalize'
  },
  input: {
    '&[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
});

export default styles;
