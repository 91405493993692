import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Snackbar } from '@mui/material';
import { withStyles } from '@mui/styles';
import Activities from '../../components/Activities';
import BottomNav from '../../components/BottomNav';
import ConfAgree from '../../components/AlertDialog/ConfAgree';
import UpdateDob from '../../components/AlertDialog/UpdateDob';
import HomeFooter from '../../components/Footer/HomeFooter';
import Header from '../../components/Header';
import ListContainer from '../../components/ListContainer';
import Logo from '../../components/Logo';
import SuperTab from '../../components/SuperTab';
import ApiPath from '../../constants/api';
import { AUTO_HIDE_DURATION, HOME_TAB_ITEMS } from '../../constants/common';
import { useWindowSize } from '../../hooks';
import apiCall from '../../utils/api';
import { isEmptyString } from '../../utils/common';
import API, { API_STATUS_CODE } from '../../constants/common';
import styles from './styles';

const Home = ({ classes }) => {
  const onLoadSuccess = [
    'homeLogoEvent',
    'signIn',
    'termsUseClose',
    'unsubCancel',
    'backFromProfile'
  ];
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [confAgree, setConfAgree] = useState(false);
  const [offset, setOffset] = useState(0);
  const [openDobUpdate, setOpenDobUpdate] = useState(false);
  const [resetTab, setResetTab] = useState(false);
  const [tab, setTab] = useState('activities');
  const [userName, setUserName] = useState('');
  const [newDateOfBirth, setNewDateOfBirth] = useState({
    screenName: '',
    day: '',
    month: '',
    year: ''
  });
  const [isDobUpdated, setIsDobUpdated] = useState(true);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [radioOption, setRadioOption] = useState('');
  const { width } = useWindowSize();

  const changeTab = (tabName) => {
    setTab(tabName);
  };
  const changeInnerTab = (reset) => {
    setResetTab(reset);
  };

  useEffect(() => {
    apiCall(`${ApiPath.status}`, API.METHOD.GET, {}, false).then((response) => {
      if (response?.error) {
        setIsSnackbarOpen(true);
        setSnackbarMessage(
          `Confidentiality Agreement Error: ${response?.error?.message}`
        );
      }
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        const { confAgree, dobUpdated } = response?.data;
        setIsDobUpdated(dobUpdated);
        if (confAgree === 'UNSET' || confAgree === 'NO') {
          setConfAgree(true);
        } else {
          setOpenDobUpdate(!dobUpdated);
        }
      }
    });
  }, [isDobUpdated]);

  useEffect(() => {
    onLoadSuccess.includes(location.state)
      ? apiCall(`${ApiPath.authValidation}`, API.METHOD.GET, {}, false).then(
          (response) => {
            if (response?.error) {
              setIsSnackbarOpen(true);
              setSnackbarMessage(
                `${t('errorMsgs.session_time_out')} : ${
                  response?.error?.message
                }`
              );
            }
            if (response?.status === API_STATUS_CODE.SUCCESS) {
              setUserName(response.data?.message?.split(/(?:[ ])+/)[1]);
            }
          }
        )
      : navigate('/login', { state: 'browserEvent' });
    window.onscroll = (event) => {
      setOffset(window.pageYOffset);
    };
  }, [location]);

  useEffect(() => {
    if (!isEmptyString(radioOption)) {
      const payload = {
        confAgree: radioOption
      };
      apiCall(`${ApiPath.termsAgreement}`, API.METHOD.PUT, payload, false).then(
        (response) => {
          if (response?.error) {
            setIsSnackbarOpen(true);
            setSnackbarMessage(
              `Confidentiality Agreement Error: ${response?.error?.message}`
            );
          }
          if (radioOption === 'NO') {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            navigate('/home', { replace: true, state: '' });
            navigate('/login', { state: 'logOff' });
          } else {
            setOpenDobUpdate(!isDobUpdated);
          }
        }
      );
    }
  }, [confAgree]);

  const tabContent = (
    <>
      {tab === 'activities' ? (
        <Activities />
      ) : tab === 'history' ? (
        <ListContainer type="activityHistory" />
      ) : null}
    </>
  );

  return (
    <>
      <title>Comunidad Spark: {t('common.home')}</title>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        message={snackbarMessage}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={() => setIsSnackbarOpen(false)}
        ContentProps={{
          classes: {
            root: classes.snackbarRoot
          }
        }}
      />
      <ConfAgree
        openDialog={confAgree}
        setOpenDialog={setConfAgree}
        radioOption={radioOption}
        setRadioOption={setRadioOption}
      />
      <UpdateDob
        newDateOfBirth={newDateOfBirth}
        openDialog={openDobUpdate}
        setNewDateOfBirth={setNewDateOfBirth}
        setOpenDialog={setOpenDobUpdate}
        setIsSnackbarOpen={setIsSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
      />

      {width <= 600 ? (
        <>
          <Header />
          <div>
            <Logo type={'hero'} flag={offset > 70 ? false : true} />
            {tabContent}
            <BottomNav
              handleTab={changeTab}
              resetInnerTab={changeInnerTab}
              user={userName}
            />
          </div>
        </>
      ) : (
        <>
          <Header user={userName} />
          <Grid container className={classes.gridContainer}>
            <Logo type={'hero'} flag={offset > 100 ? false : true} />
            <Grid
              className={`${classes.actionRow} ${
                offset > 190 ? classes.tabFix : ''
              }`}
            >
              <SuperTab
                changeTab={changeTab}
                resetInnerTab={changeInnerTab}
                tabItems={HOME_TAB_ITEMS}
              />
            </Grid>
            <div
              role="tabpanel"
              style={{ justifyContent: 'center', width: 'inherit' }}
            >
              {tabContent}
            </div>
          </Grid>
          <HomeFooter />
        </>
      )}
    </>
  );
};

Home.defaultProps = {
  classes: {}
};

Home.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Home);
