import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';

const OkCancel = ({
  classes,
  open,
  handleClose,
  title,
  content,
  primaryBtnClick,
  primaryBtnText,
  secondaryBtnClick,
  secondaryBtnText,
  type,
  isDisabled
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="title"
      aria-describedby="description"
      PaperProps={{
        classes: {
          root: classes.paper
        }
      }}
      classes={{
        root: classes.paper,
        paper: type === 'mini' ? classes.confirm : undefined
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrops
        }
      }}
    >
      {title ? (
        <DialogTitle id="title" className={classes.title}>
          {title}
        </DialogTitle>
      ) : null}
      {content ? (
        <DialogContent>
          <DialogContentText id="description" className={classes.contents}>
            {content}
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions className={classes.actions}>
        {secondaryBtnText ? (
          <Button
            className={classes.btn}
            onClick={() => secondaryBtnClick()}
            color="primary"
          >
            {secondaryBtnText}
          </Button>
        ) : null}
        {primaryBtnText ? (
          <Button
            className={classes.btn}
            onClick={() => primaryBtnClick()}
            color="primary"
            variant="contained"
            disabled={isDisabled}
          >
            {primaryBtnText}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

OkCancel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  primaryBtnClick: PropTypes.func.isRequired,
  primaryBtnText: PropTypes.string,
  secondaryBtnClick: PropTypes.func,
  secondaryBtnText: PropTypes.string,
  type: PropTypes.string
};

export default withStyles(styles)(OkCancel);
