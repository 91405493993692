const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '8px 16px 80px',
    minHeight: '200vw',
    position: 'sticky',
    [theme.breakpoints.up('sm')]: {
      marginTop: 8,
      padding: '8px 16px',
      minHeight: '65vw'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 13px',
      marginTop: 24,
      marginBottom: 20,
      minHeight: '50vw'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 30px',
      minHeight: '32vw'
    }
  },
  button: {
    width: 312,
    height: 40,
    fontSize: 14,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 119
    },
    '&.Mui-disabled': {
      backgroundColor: '#6d6e71',
      color: '#ffffff'
    }
  },
  buttonHolder: {
    display: 'flex',
    marginTop: 40,
    [theme.breakpoints.up('sm')]: {
      width: 344,
      justifyContent: 'space-around'
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412
    }
  },
  noActWrapper: {
    minHeight: '80%',
    position: 'absolute',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#333333',
    marginTop: 40,
    fontSize: 14,
    '& p': {
      '&:nth-child(1)': {
        width: '90%',
        marginBottom: 32,
        textAlign: 'center'
      },
      '&:nth-child(3)': {
        marginTop: 32,
        [theme.breakpoints.up('sm')]: {
          width: 639
        }
      }
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '50vw',
      fontSize: 18,
      position: 'relative'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '40vw'
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '30vw'
    }
  },
  snackbarRoot: {
    maxWidth: 444,
    fontSize: 16,
    color: '#0089e2',
    backgroundColor: '#e5f0fa',
    fontWeight: '500',
    textAlign: 'center'
  },
  skeletonWrapper: {
    width: '100%'
  }
});

export default styles;
