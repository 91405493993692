/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  Link,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Logo from '../../Logo';
import styles from './styles';

const Activity = ({ classes, imageType, name, surveyLink }) => {
  const { t } = useTranslation();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div className={classes.contentWrapper}>
          <div className={classes.titleWrapper}>
            <Typography className={classes.title}>{name}</Typography>
            {/* <Typography className={classes.points}>
              {points + ' pts'}
            </Typography> */}
          </div>
          <div className={classes.imgWrapper}>
            <Logo type={'activity'} flag={imageType} />
          </div>
        </div>
      </CardContent>
      <CardActions classes={{ root: classes.actionRoot }} disableSpacing>
        <div className={classes.linkWrapper}>
          <Link
            href={surveyLink}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.action}
            underline="none"
          >
            {t('home.beginActivity')}
          </Link>
        </div>
      </CardActions>
    </Card>
  );
};

Activity.defaultProps = {
  classes: {}
};

Activity.propTypes = {
  classes: PropTypes.object,
  imageType: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  surveyLink: PropTypes.string.isRequired
};

export default withStyles(styles)(Activity);
