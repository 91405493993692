import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Link,
  Select,
  Snackbar,
  TextField
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  ArrowBack,
  AssignmentOutlined,
  CakeOutlined,
  PersonOutlineOutlined
} from '@mui/icons-material';
import Unsubscribe from '../../components/AlertDialog/Unsubscribe';
import UpdateAvatar from '../../components/UpdateAvatar';
import Header from '../../components/Header';
import HomeFooter from '../../components/Footer/HomeFooter';
import Logo from '../../components/Logo';
import { useWindowSize } from '../../hooks';
import apiCall from '../../utils/api';
import api from '../../constants/api';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION,
  AVATARS,
  TOAST_MSGS
} from '../../constants/common';
import { SELECT_MENU_POPOVER_STYLE } from '../../constants/styles';
import styles from './styles';

const ProfileOld = ({ classes }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [activity, setActivity] = useState({
    frequency: '',
    id: 0,
    period: ''
  });
  const [prefEdit, setPrefEdit] = useState(false);
  const [prevActivity, setPrevActivity] = useState({});
  const [prevName, setPrevName] = useState('');
  const [profile, setProfile] = useState({ screenName: '', dateOfBirth: '', avatar: 0 });
  const [profileEdit, setProfileEdit] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [unsubscribe, setUnsubscribe] = useState(false);
  const [openSelect, setOpenSelect] = useState({});
  const [editAvatar, setEditAvatar] = useState(false);

  useEffect(() => {
    apiCall(api.profile, API.METHOD.GET, {}, false).then(response => {
      if (response?.error) {
        setIsSnackbarOpen(true);
        setSnackbarMessage(response?.error?.message);
      }

      if (response?.status === API_STATUS_CODE.SUCCESS) {
        const { screenName, dateOfBirth, level, avatar } = response.data;
        let dob = '';

        if (dateOfBirth) {
          dob = dateOfBirth?.split('-');
          dob.push(dob[0]);
          dob.shift();
          dob = dob.join('/')
        }

        setProfile({ ...profile, screenName, dateOfBirth: dob, level, avatar });
      }
    });
  }, [api.profile]);

  useEffect(() => {
    apiCall(api.activityPref, API.METHOD.GET, {}, false).then(response => {
      if (response?.error) {
        setIsSnackbarOpen(true);
        setSnackbarMessage(response?.error?.message);
      }

      if (response?.status === API_STATUS_CODE.SUCCESS) {
        const { frequency, period } = response?.data?.activityPreferences[0];

        setActivity({ ...activity, frequency, period });
      }
    });
  }, [api.activityPref]);

  const handleActivityCount = event => {
    setActivity({ ...activity, frequency: Number(event.target.value) });
  };

  const handleActivityPeriod = event => {
    setActivity({ ...activity, period: event.target.value });
  };

  const handleBackClick = event => {
    navigate('/home', { state: 'backFromProfile' });
  };

  const handleCancel = type => event => {
    if (type === 'profile') {
      setProfile({ ...profile, screenName: prevName });
      setProfileEdit(false);
    } else {
      setActivity(prevActivity);
      setPrefEdit(false);
    }
  };

  const handleEdit = type => event => {
    if (type === 'profile') {
      setProfileEdit(true);
      setPrevName(profile.screenName);
    } else {
      setPrefEdit(true);
      setPrevActivity(activity);
    }
  };

  const handleProfileName = event => {
    setProfile({ ...profile, screenName: event.target.value });
  };

  const handleSave = type => event => {
    if (type === 'profile') {
      handleProfileUpdate();
      setProfileEdit(false);
    } else {
      handleActPrefUpdate();
      setPrefEdit(false);
    }
  };

  const handleProfileUpdate = (avatarId = profile.avatar) => {
    const payload = {
      avatar: avatarId.toString(),
      screenName: profile.screenName
    };

    apiCall(api.profile, API.METHOD.PUT, payload, false).then(response => {
      if (response?.error) {
        setSnackbarMessage(response?.error?.message);
      }

      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setSnackbarMessage(TOAST_MSGS.profile_info_update);
        setProfile({ ...profile, avatar: avatarId });
      }

      setIsSnackbarOpen(true);
    });
  };

  const handleActPrefUpdate = () => {
    const payload = {
      activityPreferences: [
        {
          id: 1,
          frequency: activity.frequency,
          period: activity.period
        }
      ]
    };

    apiCall(api.activityPref, API.METHOD.PUT, payload, false).then(
      response => {
        if (response?.error) {
          setSnackbarMessage(response?.error?.message);
        }

        if (response?.status === API_STATUS_CODE.SUCCESS) {
          setSnackbarMessage(TOAST_MSGS.activity_pref_update);
        }

        setIsSnackbarOpen(true);
      }
    );
  };

  const handleUnsubscribe = () => {
    setUnsubscribe(true);
  };

  const handleEditAvatar = () => {
    setEditAvatar(true);
  }

  const getEditOrSaveButton = (content, contentFlag) =>
    !contentFlag ? (
      <Link
        component="button"
        className={classes.profileEdit}
        onClick={handleEdit(content)}
        underline="none"
        data-testid={content}
      >
        {t('link.edit')}
      </Link>
    ) : (
      <div className={classes.profileSave}>
        <Link
          component="button"
          className={classes.profileEdit}
          onClick={handleCancel(content)}
          underline="none"
          data-testid={`${content}-cancel`}
        >
          {t('link.cancel')}
        </Link>
        <Button
          id="save"
          className={classes.saveButton}
          variant="outlined"
          color="primary"
          onClick={handleSave(content)}
          data-testid={`${content}-save`}
        >
          {t('link.save')}
        </Button>
      </div>
    );

  const activityEditWrapper = (
    <div
      className={classes.activityEditWrapper}
      style={{ height: openSelect.count ? 220 : openSelect.period ? 140 : 63 }}
    >
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="times-label">{t('profile.times')}</InputLabel>
        <Select
          labelId="times-label"
          id="times"
          value={activity.frequency}
          onChange={handleActivityCount}
          label={t('profile.times')}
          open={openSelect.count}
          onOpen={() => setOpenSelect({ ...openSelect, count: true })}
          onClose={() => setOpenSelect({ ...openSelect, count: false })}
          MenuProps={{
            ...SELECT_MENU_POPOVER_STYLE,
            style: {
              maxHeight: 300
            }
          }}
        >
          <MenuItem value="0">{t('profile.unlimited')}</MenuItem>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => (
            <MenuItem value={i.toString()}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <p>{t('profile.per')}</p>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="period-label">{t('profile.period')}</InputLabel>
        <Select
          labelId="period-label"
          id="period"
          value={activity.period}
          onChange={handleActivityPeriod}
          label={t('profile.period')}
          open={openSelect.period}
          onOpen={() => setOpenSelect({ ...openSelect, period: true })}
          onClose={() => setOpenSelect({ ...openSelect, period: false })}
          MenuProps={SELECT_MENU_POPOVER_STYLE}
        >
          <MenuItem value="DAY">{t('profile.day')}</MenuItem>
          <MenuItem value="MONTH">{t('profile.month')}</MenuItem>
          <MenuItem value="YEAR">{t('profile.year')}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  const Avatar = () => {
    const { path } = AVATARS.find(avatar => avatar.id === profile.avatar);

    return (<img src={path} alt='avatar' />);
  }

  const avatarWrapper = (
    <div className={classes.avatarWrapper}>
      {profile.avatar == 0 && <Logo type="avatar" />}
      {profile.avatar > 0 && <Avatar />}
      <Link component="button" className={classes.avatarEdit} underline="none" onClick={handleEditAvatar}>
        {t('link.edit')}
      </Link>
    </div>
  );

  const backClick = (
    <Link
      component="button"
      onClick={handleBackClick}
      className={classes.backClick}
      underline="none"
      data-testid="backToComLink"
    >
      <ArrowBack className={classes.icon} />
      <p>{t('link.backToCom')}</p>
    </Link>
  );

  const editName = (
    <TextField
      id="name"
      className={classes.textField}
      variant="outlined"
      label={t('profile.name')}
      onChange={handleProfileName}
      value={profile.screenName}
      required
      data-testid="screen-name"
    />
  );

  const profileHeader = (
    <>
      <h1 className={classes.profileH1}>{t('profile.header')}</h1>
      <h2 className={classes.profileH2}>{t('profile.subHeader')}</h2>
    </>
  );

  const snackBanner = (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isSnackbarOpen}
      message={snackbarMessage}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={() => setIsSnackbarOpen(false)}
      ContentProps={{
        classes: {
          root: classes.snackbarRoot
        }
      }}
    />
  );

  const unsubscribeWrapper = (
    <div className={classes.unsubscribeWrapper}>
      <Button
        id="unsubscribe"
        className={classes.unsubscribe}
        variant="outlined"
        color="primary"
        onClick={handleUnsubscribe}
      >
        {t('link.unsubscribe')}
      </Button>
    </div>
  );

  const getPeriodTranslation = inEngData => {
    switch(inEngData) {
      case 'day':
        return t('profile.day')
        case 'month':
          return t('profile.month')
        case 'year':
          return t('profile.year')
        default:
          return ''
    }
  }

  return (
    <>
      <Header />
      {unsubscribe && (
        <Unsubscribe openDialog={unsubscribe} setOpenDialog={setUnsubscribe} />
      )}
      {editAvatar && (
        <UpdateAvatar
          openDialog={editAvatar}
          setOpenDialog={setEditAvatar}
          level={profile.level}
          profileUpdateHandler={handleProfileUpdate}
        />
      )}
      {width <= 960 ? (
        <>
          <div className={classes.root}>
            {snackBanner}
            {backClick}
            <div className={classes.profile}>
              {profileHeader}
              {avatarWrapper}
              <div className={classes.profileHead}>
                <h3 className={classes.profileH3}>{t('profile.info')}</h3>
                {getEditOrSaveButton('profile', profileEdit)}
              </div>
              <div className={classes.profileH3Wrapper}>
                <div className={classes.profileH3IconWrap}>
                  <PersonOutlineOutlined
                    className={`${classes.iconPadding} ${profileEdit ? 'addExtra' : undefined
                      }`}
                  />
                  <CakeOutlined />
                </div>
                <div
                  className={profileEdit ? classes.wrapperMdView : undefined}
                >
                  <div
                    className={`${classes.profileRowWrap} ${profileEdit ? 'textFieldFlex' : undefined
                      }`}
                  >
                    <p className={classes.profileH3Name}>{t('profile.name')}</p>
                    {!profileEdit && (
                      <p className={classes.profileH3Value}>
                        {profile.screenName}
                      </p>
                    )}
                    {profileEdit && editName}
                  </div>
                  <div className={classes.profileRowWrap}>
                    <p className={classes.profileH3Name}>{t('profile.dob')}</p>
                    <p className={classes.profileH3Value}>
                      {profile.dateOfBirth}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div className={classes.profileHead}>
                <h3 className={classes.profileH3}>
                  {t('profile.activityPref')}
                </h3>
                {getEditOrSaveButton('pref', prefEdit)}
              </div>
              <div className={classes.profileH3Wrapper}>
                <div className={classes.profileH3IconWrap}>
                  <AssignmentOutlined />
                </div>
                <div
                  className={`${classes.profileRowWrap} ${prefEdit ? 'textFieldFlex' : undefined
                    }`}
                >
                  <p className={classes.profileH3Name}>
                    {t('profile.activityCount')}
                  </p>
                  {!prefEdit && (
                    <p className={classes.profileH3Value}>
                      {!activity.frequency || activity.frequency === 0
                        ? t('profile.unlimited')
                        : activity.frequency}{' '}
                      {activity.frequency === 1
                        ? t('profile.time')
                        : t('profile.times')}{' '}
                      {t('profile.per')}{' '}
                      {activity.period
                        ? getPeriodTranslation(activity.period.toLowerCase())
                        : t('profile.month')}
                    </p>
                  )}
                  {prefEdit && activityEditWrapper}
                </div>
              </div>
              <hr />
              {unsubscribeWrapper}
            </div>
          </div>
          {width > 360 ? <HomeFooter /> : null}
        </>
      ) : (
        <>
          <div className={classes.root}>
            {snackBanner}
            <div className={classes.avatarSection}>
              {backClick}
              {avatarWrapper}
            </div>
            <div className={classes.profile}>
              {profileHeader}
              <div className={classes.profileHead}>
                <h3 className={classes.profileH3}>{t('profile.info')}</h3>
                {getEditOrSaveButton('profile', profileEdit)}
              </div>
              <div className={classes.profileH3Wrapper}>
                <div className={classes.profileH3IconWrap}>
                  <PersonOutlineOutlined />
                  <CakeOutlined />
                </div>
                <div
                  className={profileEdit ? classes.wrapperMdView : undefined}
                >
                  <p className={classes.profileH3Name}>{t('profile.name')}</p>
                  {profileEdit && editName}
                  <p className={classes.profileH3Name}>{t('profile.dob')}</p>
                </div>
                <div
                  className={profileEdit ? classes.profileEditDate : undefined}
                >
                  {!profileEdit && (
                    <p className={classes.profileH3Value}>
                      {profile.screenName}
                    </p>
                  )}
                  <p className={classes.profileH3Value}>
                    {profile.dateOfBirth}
                  </p>
                </div>
              </div>
              <hr />
              <div className={classes.profileHead}>
                <h3 className={classes.profileH3}>
                  {t('profile.activityPref')}
                </h3>
                {getEditOrSaveButton('pref', prefEdit)}
              </div>
              <div className={classes.profileH3Wrapper}>
                <div className={classes.profileH3IconWrap}>
                  <AssignmentOutlined />
                </div>
                <div>
                  <p className={classes.profileH3Name}>
                    {t('profile.activityCount')}
                  </p>
                  {prefEdit && activityEditWrapper}
                </div>
                {!prefEdit && (
                  <div>
                    <p className={classes.profileH3Value}>
                      {!activity.frequency || activity.frequency === 0
                        ? t('profile.unlimited')
                        : activity.frequency}{' '}
                      {activity.frequency === 1
                        ? t('profile.time')
                        : t('profile.times')}{' '}
                      {t('profile.per')}{' '}
                      {activity.period
                        ? getPeriodTranslation(activity.period.toLowerCase())
                        : t('profile.month')}
                    </p>
                  </div>
                )}
              </div>
              <hr />
              {unsubscribeWrapper}
            </div>
          </div>
          <HomeFooter />
        </>
      )}
    </>
  );
};

ProfileOld.defaultProps = {
  classes: {}
};

ProfileOld.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ProfileOld);
