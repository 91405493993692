import config from '../config/config';

const url = config.api.customerSpark.domain + config.api.customerSpark.path;

export default {
  activityHistoryListing: `${url}/v1/panelist/activity-history`,
  activityPref: `${url}/v1/panelist/settings/activity-preference`,
  authValidation: `${url}/v1/action/hello`,
  autoRedeem: `${url}/v1/panelist/settings/auto-redeem`,
  availActivities: `${url}/v1/panelist/available-activities`,
  profile: `${url}/v1/panelist/profile`,
  redeem: `${url}/v1/panelist/redeem`,
  refreshToken: `${url}/v1/account/refresh-token`,
  register: `${url}/v1/account/register`,
  resetPassword: `${url}/v1/account/reset-password`,
  rewardHistoryListing: `${url}/v1/panelist/redeem-history`,
  rewardStatus: `${url}/v1/panelist/rewards`,
  sendOtp: `${url}/v1/account/password-reset-code`,
  settings: `${url}/v1/panelist/settings`,
  signIn: `${url}/v1/account/sign-in`,
  termsAgreement: `${url}/v1/panelist/terms-agreement`,
  status: `${url}/v1/panelist/status`,
  updateDob: `${url}/v1/panelist/updateDob`,
  unsubscribe: `${url}/v1/panelist/unsubscribe`,
  unsubExt: `${url}/v1/user/action/campaign/optout`
};
