import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, IconButton, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ArrowBack } from '@mui/icons-material';

import styles from './styles';

const TopBar = ({ title, classes, onClose }) => (
  <ButtonBase
    className={classes.container}
    onClick={() => onClose()}
    data-testid="close"
  >
    {onClose && (
      <IconButton>
        <ArrowBack />
      </IconButton>
    )}
    <Typography className={classes.content}>{title}</Typography>
  </ButtonBase>
);

TopBar.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(TopBar);
