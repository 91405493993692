import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Grid,
  LinearProgress,
  Snackbar,
  TextField
} from '@mui/material';
import { withStyles } from '@mui/styles';
import OkCancel from '../../components/AlertDialog/OkCancel';
import OnboardHeader from '../../components/OnboardHeader';
import api from '../../constants/api';
import apiCall from '../../utils/api';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION,
  EMAIL_FIELD_LENGTH,
  TOAST_MSGS
} from '../../constants/common';
import {
  decodeValue,
  getQueryStringValue,
  maskEmailId,
  isEmptyString,
  isValidEmailId
} from '../../utils/common';
import styles from './styles';

const ForgotPassword = ({ classes }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const initAlertBox = {
    open: false,
    primaryBtn: '',
    title: '',
    content: '',
    code: ''
  };
  const [alertBox, setAlertBox] = useState(initAlertBox);
  const [emailId, setEmailId] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState('');
  const [progress, setProgress] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [emailInvalid, setEmailInvalid] = useState({
    isError: false,
    message: ''
  });

  useEffect(() => {
    const isForgotPwdflow =
      getQueryStringValue(location, 'isforgotpasswordflow') === 'true' || false;
    const extractedEmailId = getQueryStringValue(location, 'invite') || '';
    const decodedEmailId = decodeValue(extractedEmailId);
    setEmailId(decodedEmailId);
    if (!isValidEmailId(decodedEmailId) && !isForgotPwdflow) {
      setIsSnackbarOpen(true);
      setSnackbarMessage(TOAST_MSGS.forgot_pword_url_error);
    }
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setAlertBox({
      open: true,
      primaryBtn: t('link.ok'),
      content: `${t('forgotPass.subHeader', {
        mailId: maskEmailId(emailId)
      })}. ${t('forgotPass.otpSuccess')}`
    });
  };

  const sendOtpCall = () => {
    const payload = {
      userId: emailId,
      registration: false
    };

    setProgress(true);
    apiCall(`${api.sendOtp}`, API.METHOD.POST, payload, true).then(
      (response) => {
        setProgress(false);
        if (response?.error) {
          setAlertBox(initAlertBox);
          setEmailInvalid({
            ...emailInvalid,
            isError: true,
            message: response.error?.message
          });
        }

        if (response?.status === API_STATUS_CODE.SUCCESS) {
          navigate(
            `/reset-password/?invite=${btoa(emailId)}&isforgotpasswordflow=true`
          );
        }
      }
    );
  };

  const handleEmailChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
    setEmailId(e.target.value?.toLowerCase());
    setEmailInvalid({ ...emailInvalid, isError: false });
    if (!isValidEmailId(e.target.value?.toLowerCase())) {
      setEmailInvalid({
        ...emailInvalid,
        isError: true,
        message: TOAST_MSGS.valid_email_address
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const cancel = () => {
    navigate(
      `/login${isValidEmailId(emailId) ? `?invite=${btoa(emailId)}` : ''}`
    );
  };

  return (
    <Grid className={classes.container}>
      <title>Comunidad Spark: {t('signIn.forgotPword')}</title>
      <Grid className={classes.formHolder}>
        <OkCancel
          open={alertBox.open}
          primaryBtnClick={sendOtpCall}
          content={alertBox.content || ''}
          primaryBtnText={alertBox.primaryBtn}
          isDisabled={progress}
        />
        {progress && (
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isSnackbarOpen}
          message={snackbarMessage}
          autoHideDuration={AUTO_HIDE_DURATION}
          onClose={() => setIsSnackbarOpen(false)}
          ContentProps={{
            classes: {
              root: classes.snackbarRoot
            }
          }}
        />
        <OnboardHeader
          headerText={t('forgotPass.header')}
          headerContent={t('forgotPass.subHeader', { mailId: emailId })}
        />
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className={classes.emailWrapper}>
            <TextField
              id="email"
              error={emailInvalid.isError}
              className={classes.textField}
              variant="outlined"
              label={t('common.email')}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              value={emailId}
              inputProps={{
                maxLength: EMAIL_FIELD_LENGTH
              }}
              FormHelperTextProps={{
                classes: { root: classes.helperText }
              }}
              data-testid="emailId"
            />
            {emailInvalid.isError && (
              <p className={classes.helperText} role="alert">
                {emailInvalid.message}
              </p>
            )}
          </div>
          <div className={classes.buttonHolder}>
            <Button
              id="fp-cancel"
              variant="outlined"
              className={classes.button}
              color="primary"
              value="Post"
              onClick={cancel}
            >
              {t('link.cancel')}
            </Button>
            <Button
              id="fp-send"
              variant="contained"
              className={classes.button}
              color="primary"
              type="submit"
              value="Post"
              disabled={
                progress || emailInvalid.isError || isEmptyString(emailId)
              }
            >
              {t('link.send')}
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

ForgotPassword.defaultProps = {
  classes: {}
};

ForgotPassword.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ForgotPassword);
