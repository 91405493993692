/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

// import Account from './containers/Account';
import Faq from './containers/Faq';
import ForgotPassword from './containers/ForgotPassword';
import Home from './containers/Home';
import Landing from './containers/Landing';
import PageNotFound from './containers/PageNotFound';
// import Profile from './containers/Profile';
import ProfileOld from './containers/ProfileOld';
import ResetPassword from './containers/ResetPassword';
import SignIn from './containers/SignIn';
import TermsOfUse from './containers/TermsOfUse';
import PrivacyPolicy from './containers/PrivacyPolicy';
import UnsubRoute from './containers/UnsubRoute';
import UnsubExt from './containers/UnsubExt';

import CCM from './components/CCM';
import API, { API_STATUS_CODE } from './constants/common';
import config from './constants/dummyCCM';
import themeStyles from './constants/styles';
import ConfigContext from './contexts/ConfigContext';
import apiCall from './utils/api';
import './App.scss';

const theme = createTheme(themeStyles);

const App = () => {
  const [ccmConfig, setCcmConfig] = useState({
    isError: false,
    isLoading: false,
    data: null
  });

  useEffect(() => {
    setCcmConfig(_ccmConfig => ({ ..._ccmConfig, isLoading: true }));

    if (typeof window !== 'undefined' && window.document) {
      const { host } = window?.location;

      if (host.includes('localhost')) {
        setCcmConfig({ isLoading: false, isError: false, data: config });
      } else {
        apiCall('/api/ccm', API.METHOD.GET).then(res => {
          if (res.status === API_STATUS_CODE.SUCCESS) {
            setCcmConfig({
              data: res?.data,
              isLoading: false,
              isError: false
            });
          } else {
            setCcmConfig({
              data: null,
              isLoading: false,
              isError: true
            });
          }
        });
      }
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ConfigContext.Provider value={ccmConfig?.data}>
          <div className="app">
            <meta charSet="utf-8" />
            <Router>
              <Routes>
                <Route exact path="/" element={<Landing />} />
                {/* <Route path="/account" element={<Account />} /> */}
                <Route path="/faq" element={<Faq />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/home" element={<Home />} />
                <Route path="/profile" element={<ProfileOld />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/login" element={<SignIn />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/unsub-ext" element={<UnsubExt />} />
                <Route path="/unsubscribe" element={<UnsubRoute />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/ccm" element={<CCM />} />
                <Route path="*" element={<PageNotFound />} />
                <Route />
              </Routes>
            </Router>
          </div>
        </ConfigContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
