const styles = (theme) => ({
  carouselAvatar: {
    width: 40,
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: 45,
      height: 45
    },
    [theme.breakpoints.up('lg')]: {
      width: 60,
      height: 60
    }
  },
  groceryBag: {
    width: 70,
    height: 103,
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 116
    },
    [theme.breakpoints.up('md')]: {
      width: 70,
      height: 102
    },
    [theme.breakpoints.up('lg')]: {
      width: 83,
      height: 120
    }
  },
  trailer: {
    width: 100,
    height: 49,
    [theme.breakpoints.up('md')]: {
      width: 90,
      height: 44
    },
    [theme.breakpoints.up('lg')]: {
      width: 135,
      height: 66
    }
  },
  shopCart: {
    width: 70,
    height: 77,
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 87
    },
    [theme.breakpoints.up('md')]: {
      width: 70,
      height: 77
    },
    [theme.breakpoints.up('lg')]: {
      width: 100,
      height: 109
    }
  },
  site: {
    width: 100,
    height: 54,
    [theme.breakpoints.up('md')]: {
      width: 90,
      height: 48
    },
    [theme.breakpoints.up('lg')]: {
      width: 135,
      height: 73
    }
  },
  boxes: {
    width: 100,
    height: 41,
    [theme.breakpoints.up('md')]: {
      width: 90,
      height: 37
    },
    [theme.breakpoints.up('lg')]: {
      width: 135,
      height: 56
    }
  },
  tech: {
    width: 100,
    height: 38,
    [theme.breakpoints.up('md')]: {
      width: 90,
      height: 35
    },
    [theme.breakpoints.up('lg')]: {
      width: 135,
      height: 51
    }
  },
  sports: {
    width: 100,
    height: 54,
    [theme.breakpoints.up('md')]: {
      width: 90,
      height: 49
    },
    [theme.breakpoints.up('lg')]: {
      width: 135,
      height: 73
    }
  },
  receipt: {
    width: 70,
    height: 72,
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 82
    },
    [theme.breakpoints.up('md')]: {
      width: 70,
      height: 72
    },
    [theme.breakpoints.up('lg')]: {
      width: 86,
      height: 88
    }
  },
  mobileSite: {
    width: 70,
    height: 79,
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 89
    },
    [theme.breakpoints.up('md')]: {
      width: 70,
      height: 79
    },
    [theme.breakpoints.up('lg')]: {
      width: 86,
      height: 96
    }
  },
  appLogo: {
    width: 143,
    height: 20,
    marginLeft: 16,
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.up('sm')]: {
      width: 174,
      height: 24
    },
    [theme.breakpoints.up('md')]: {
      width: 174,
      height: 24,
      marginLeft: 14
    },
    [theme.breakpoints.up('lg')]: {
      width: 210,
      height: 29,
      marginLeft: 30
    }
  },
  emptyCart: {
    width: 80,
    height: 80,
    [theme.breakpoints.up('sm')]: {
      width: 97,
      height: 100
    }
  },
  headerImage: {
    width: '100%',
    height: '100%'
  },
  heroImage: {
    width: '100%',
    marginTop: 64,
    objectFit: 'contain'
  },
  pageNotFound: {
    width: 300,
    height: 234,
    [theme.breakpoints.up('sm')]: {
      width: 360,
      height: 281
    },
    [theme.breakpoints.up('md')]: {
      width: 318,
      height: 248
    },
    [theme.breakpoints.up('lg')]: {
      width: 400,
      height: 312
    }
  },
  resultsNotFound: {
    width: 188,
    height: 123,
    [theme.breakpoints.up('sm')]: {
      width: 204,
      height: 134
    },
    [theme.breakpoints.up('md')]: {
      width: 194,
      height: 127
    },
    [theme.breakpoints.up('lg')]: {
      width: 210,
      height: 137
    }
  },
  avatar: {
    width: 40,
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: 141,
      height: 141
    }
  },
  confAgree: {
    width: 284,
    height: 29
  },
  landing: {
    width: 168,
    height: 24,
    marginLeft: 16
  },
  wwp: {
    width: 144,
    height: 256
  }
});

export default styles;
