const styles = (theme) => ({
  actionButtonContainer: {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: 50
    },
    [theme.breakpoints.down('lg')]: {
      padding: 10,
    }
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '12px'
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '0 !important'
  },
  actionButton: {
    textTransform: 'none !important', 
    margin: '0 16px !important'
  },
  avatarPickerContainer: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'center',
    marginBottom: '32px !important'
  },
  avatarPickerImage: {
    height: '128px',
    width: '128px',
    marginBottom: '12px'
  },
  greyImage: {
    filter: 'grayscale(100%) opacity(90%)'
  },
  contentText: {
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
    color: '#414042'
  },
  dialog: {
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    // minHeight: '84vh',
    maxHeight: '84vh !important'
  },
  dialogContent: {
    padding: '0 16px',
  },
  lockedIcon: {
    color: '#8B8A8E',
    padding: 9
  },
  completeMoreActivitiesSubtitle: {
    paddingBottom: 32,
    textAlign: 'center'
  }
});

export default styles;
