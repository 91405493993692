import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Link, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { FOOTER_LINKS } from '../../../constants/common';
import styles from './styles';

const HomeFooter = ({ classes, type }) => {
  const { t } = useTranslation();

  return (
    <Grid container className={classes.wrapper}>
      <Grid
        container
        item
        justify={type === 'landing' ? 'center' : 'space-evenly'}
        className={classes.linkWrapper}
      >
        {FOOTER_LINKS.map((link, index) => {
          let props = {
            key: index,
            href: link.path,
            rel: 'noopener noreferrer',
            color: 'inherit',
            underline: 'none',
            target: '_blank'
          };
          if (type !== 'landing') {
            if (
              link.name.indexOf('FAQ') !== -1 ||
              link.name.indexOf('Unsubscribe') !== -1 ||
              link.name.indexOf('Privacy Policy') !== -1 ||
              link.name.indexOf('Terms of Use') !== -1
            ) {
              props = { ...props, target: '_self' };
            }
            return (
              <Link {...props} className={classes.link}>
                {link.name}
              </Link>
            );
          } else {
            if (link.name.indexOf('FAQ') !== -1) {
              props = { ...props, target: '_self' };
            }
            return [0, 1, 4].includes(index) ? (
              <Link {...props} className={classes.link}>
                {link.name}
              </Link>
            ) : undefined;
          }
        })}
      </Grid>
      <Grid item>
        <Typography className={classes.copyright}>
          <strong>
            {t('footer.copyrights', { currentYear: new Date().getFullYear() })}
          </strong>
        </Typography>
        <Typography className={classes.copyright}>
          {t('footer.reserve')}
        </Typography>
      </Grid>
    </Grid>
  );
};

HomeFooter.defaultProps = {
  classes: {},
  type: ''
};

HomeFooter.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string
};

export default withStyles(styles)(HomeFooter);
