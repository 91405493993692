import React, { useEffect, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import GiftCardDetail from '../GiftCardDetail';
import List from '../List';
import API, {
  API_STATUS_CODE,
  LIST_TABLE_MAPPING,
  LIST_SIZE,
  SORT_VALUE
} from '../../constants/common';
import apiCall from '../../utils/api';
import api from '../../constants/api';

const ListContainer = ({ type }) => {
  const firstLoad = useRef(true);
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    attribute: 'timestamp',
    order: SORT_VALUE[0]
  });
  const [hasNextPage, setHasNextPage] = useState(false);
  const [toggleCallListing, setToggleCallListing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rewardDetail, setRewardDetail] = useState(null);

  useEffect(() => {
    resetState();
  }, [type]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    } else {
      ListingDataCall();
    }
  }, [page, sort, toggleCallListing]);

  const resetState = () => {
    setListData([]);
    setPage(1);
    setHasNextPage(true);
    setToggleCallListing((toggleCallListing) => !toggleCallListing);
    setIsLoading(true);
  };

  const ListingDataCall = () => {
    const payload = {
      page,
      sort,
      size: LIST_SIZE
    };

    const url = api.activityHistoryListing;
    apiCall(url, API.METHOD.POST, payload, false).then((response) => {
      if (response?.error) {
        setIsError(true);
      }

      if (response?.status === API_STATUS_CODE.SUCCESS) {
        const newListData = listData
          .concat(response.data.data)
          .map((item, index) => ({
            ...item,
            id: index,
            showPopup: item?.status === 'Redeemed',
            linkTitle: 'See Details'
          }));

        setListData(newListData);
        setHasNextPage(response.data && response.data.hasNextPage);
        setIsLoading(false);
        setIsError(false);
      }
    });
  };

  const handlePageUp = useCallback(() => {
    setPage((page) => page + 1);
  }, []);

  const handleSort = (attribute) => {
    const sortObj =
      sort && sort.attribute === attribute && sort.order === SORT_VALUE[0]
        ? { attribute, order: SORT_VALUE[1] }
        : { attribute, order: SORT_VALUE[0] };

    setListData([]);
    setHasNextPage(true);
    setSort(sortObj);
    setPage(1);
    setIsLoading(true);
  };

  const handleOnClick = (id) => {
    if (type === 'rewardsHistory') {
      const rewardDetail = listData?.find((item) => item.id === id);
      if (rewardDetail?.status === 'Redeemed') {
        setRewardDetail(listData?.find((item) => item.id === id));
        setIsDialogOpen(true);
      }
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setRewardDetail(null);
  };

  return (
    <>
      {isDialogOpen ? (
        <GiftCardDetail
          handleClose={closeDialog}
          giftCardInfo={rewardDetail}
          openDialog={isDialogOpen}
        />
      ) : null}
      <List
        data={listData}
        fieldMapping={LIST_TABLE_MAPPING[type]}
        handlePageUp={handlePageUp}
        handleSort={handleSort}
        isLoading={isLoading}
        isError={isError}
        SkeletonCount={LIST_SIZE}
        hasNextPage={hasNextPage}
        sort={sort}
        onCellClick={handleOnClick}
        clickableFieldId="id"
      />
    </>
  );
};

ListContainer.propTypes = {
  type: PropTypes.string.isRequired
};

export default ListContainer;
