import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Logo from '../Logo';
import styles from './styles';

const ListNoResult = ({ classes }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.noResultWrapper}>
      <Logo type="resultsNotFound" />
      <p className={classes.title} data-testid="no-results">
        {t('common.noResults')}
      </p>
    </div>
  );
};

ListNoResult.defaultProps = {
  classes: {}
};

ListNoResult.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ListNoResult);
