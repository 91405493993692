import React from 'react';
import Iframe from '../../components/Iframe';
import { FOOTER_LINKS } from '../../constants/common';

const TermsOfUse = () => {
  return (
    <Iframe
      url={FOOTER_LINKS[3]?.url}
      title="Terms of Use"
      type="termsUseEvent"
    />
  );
};

export default TermsOfUse;
