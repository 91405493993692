import React from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import Logo from '../Logo';
import styles from './styles';

const OnboardHeader = ({ classes, headerText, headerContent }) => (
  <>
    <Grid className={classes.headerImgHolder}>
      <Logo type="onboard" />
    </Grid>
    <h1 className={classes.header}>{headerText}</h1>
    <h2 className={classes.headerContent}>{headerContent}</h2>
  </>
);

export default withStyles(styles)(OnboardHeader);
