const styles = (theme) => ({
  contentBody: {
    fontSize: 16,
    color: '#333333',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px !important'
    }
  },
  contentHead: {
    margin: '32px 0 16px !important',
    fontSize: 20,
    fontWeight: '500 !important',
    color: '#333333',
    '&.smallHead': {
      fontSize: 16
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px !important',
      '&.smallHead': {
        fontSize: 20
      }
    }
  },
  faqLink: {
    color: '#0071ce !important'
  },
  faqWrapper: {
    margin: '24px 14px 24px 18px !important',
    [theme.breakpoints.up('sm')]: {
      margin: '24px 56px !important'
    },
    [theme.breakpoints.up('md')]: {
      margin: '24px 75px !important'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '24px 125px 18px !important'
    }
  },
  gridContainer: {
    maxWidth: '100vw',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    overflowX: 'hidden'
  },
  header: {
    display: 'flex',
    width: '100vw',
    position: '-webkit-sticky',
    position: 'sticky',
    top: 0,
    zIndex: 2
  },
  headVideoWrapper: {
    minHeight: 90,
    '& div div div div': {
      paddingTop: '28% !important'
    },
    '& .video-js .vjs-dock-text': {
      display: 'none'
    },
    '& .bc-iframe, .bc-iframe body, .bc-player-default_default, .bc-player-default_default .vjs-poster':
      {
        backgroundColor: 'white'
      },
    [theme.breakpoints.up('sm')]: {
      width: '100vw',
      minHeight: 200
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 280
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 400
    }
  },
  landingWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  loginBtn: {
    width: 108,
    height: 40,
    fontSize: 14,
    marginRight: '16px !important',
    textTransform: 'none !important',
    [theme.breakpoints.up('sm')]: {
      height: 36
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '28px !important'
    },
    [theme.breakpoints.up('lg')]: {
      width: 112,
      height: 40,
      marginRight: '32px !important'
    }
  },
  nav: {
    minWidth: '100vw',
    display: 'flex',
    alignItems: 'center',
    // position: 'fixed',
    top: 0,
    height: 60,
    background: '#fff',
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      height: 64,
      justifyContent: 'space-between'
    }
  },
  note: {
    fontWeight: 300,
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 20
    }
  },
  qsContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
      position: 'relative !important',
      flexWrap: 'nowrap !important'
    }
  },
  qsHead: {
    margin: '0 0 16px !important',
    fontSize: '20px !important',
    fontWeight: '500 !important',
    color: '#333333'
  },
  qsWrapper: {
    padding: '32px 16px',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      padding: '34px 50px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '54px 73px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '164px 105px 164px 123px'
    }
  },
  seeMore: {
    fontSize: 14,
    fontWeight: 500,
    color: '#0071ce !important',
    cursor: 'pointer'
  },
  videoWrapper: {
    minHeight: 200,
    '& .bc-iframe, .bc-iframe body, .bc-player-default_default, .bc-player-default_default .vjs-poster':
      {
        backgroundColor: 'white'
      },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      alignSelf: 'center'
    },
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  }
});

export default styles;
