const config = {
  dev: {
    api: {
      customerSpark: {
        domain: 'https://dev.api.customersparkservice.targetally.walmart.com',
        path: '/targetally/customer-spark'
      }
    },
    consumerId: 'a7fced3a-8aaa-424d-8075-ac3ce5856989',
    svc_version: '1.0.0',
    iam: {
      url: 'https://qa.iam.platform.prod.walmart.com/platform-iam-server/iam/authnService/guest',
      realm: 'CUSTOMER_PORTAL',
      serviceName: 'platform-iam-server',
      serviceEnv: 'qa'
    }
  },
  stage: {
    api: {
      customerSpark: {
        domain: 'https://stg.api.customersparkservice.targetally.walmart.com',
        path: '/targetally/customer-spark'
      }
    },
    consumerId: '0f9940f0-bd09-4c81-866b-ab2b36675330',
    svc_version: '1.0.0',
    iam: {
      url: 'https://stg.iam.platform.prod.walmart.com/platform-iam-server/iam/authnService/guest',
      realm: 'CUSTOMER_PORTAL',
      serviceName: 'platform-iam-server',
      serviceEnv: 'stg'
    }
  }
  // prod: {
  //   api: {
  //     customerSpark: {
  //       domain:
  //       'https://developer.api.us.walmart.com/api-proxy/service/target-ally/customer-spark-service/v1',
  //       path: '/targetally/customer-spark'
  //     }
  //   },
  //   consumerId: 'e41d88ed-a6e6-41f5-8315-2288cd269b27',
  //   svc_version: '1.0.0',
  //   iam: {
  //     url: 'https://prod1.iam.platform.prod.walmart.com/platform-iam-server/iam/authnService/guest',
  //     realm: 'CUSTOMER_PORTAL',
  //     serviceName: 'platform-iam-server',
  //     serviceEnv: 'prod'
  //   }
  // }
};

export default config;
