import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Unsubscribe from '../../components/AlertDialog/Unsubscribe';
import { getQueryStringValue, isEmptyString } from '../../utils/common';

const UnsubRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const flow = getQueryStringValue(location, 'flow') || '';
  const [unsubscribe, setUnsubscribe] = useState(true);

  useEffect(() => {
    if (!isEmptyString(flow) && flow === 'email') {
      navigate('/login', { state: 'unsubEvent' });
    }
  }, []);

  useEffect(() => {
    if (!isEmptyString(flow) && flow === 'bottomNav' && !unsubscribe) {
      navigate('/home', { state: 'unsubCancel' });
    }
  }, [unsubscribe]);

  return (
    <Unsubscribe openDialog={unsubscribe} setOpenDialog={setUnsubscribe} />
  );
};

export default UnsubRoute;
