const styles = (theme) => ({
  actionRow: {
    width: 'inherit',
    marginTop: 24,
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #dadce0',
    [theme.breakpoints.up('sm')]: {
      marginTop: 23
    }
  },
  gridContainer: {
    maxWidth: '100vw',
    flexDirection: 'column',
    backgroundColor: '#ffffff'
  },
  header: {
    display: 'flex',
    width: '100vw'
  },
  snackbarRoot: {
    color: '#0089e2',
    backgroundColor: '#e5f0fa',
    fontSize: 16,
    fontWeight: '500',
    maxWidth: 444,
    textAlign: 'center'
  },
  tabFix: {
    position: 'sticky',
    top: '7%',
    zIndex: 1
  }
});

export default styles;
