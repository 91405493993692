import Cookies from 'js-cookie';
import {
  REGEXP_PASSWORD,
  REGEXP_OTP,
  REGEXP_EMAILID
} from '../constants/common';

export const isEmptyString = (str) => {
  return !str || 0 === str.length;
};

export const isValidArray = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0;
};

export const isValidPassword = (pword) => {
  return REGEXP_PASSWORD.test(pword);
};

export const isValidOtp = (otpToken) => {
  return otpToken && REGEXP_OTP.test(otpToken);
};

export const isValidEmailId = (emailId) => {
  return emailId && !isEmptyString(emailId) && REGEXP_EMAILID.test(emailId);
};

export const maskEmailId = (emailId) => {
  if (isValidEmailId(emailId)) {
    const emailParts = emailId.split('@');
    return emailParts[0].substr(0, 3) + '•••@' + emailParts[1];
  }
  return '';
};

export const getQueryStringValue = (location, key) => {
  const params = new URLSearchParams(location.search);
  return params.get(key);
};

export const getDotLocation = (track) => {
  let x, y;
  x = 40;
  y = 235;

  switch (track) {
    case 15:
      x -= 35;
      y -= 120;
      break;
    case 30:
      x += 40;
      y -= 220;
      break;
    case 45:
      x += 160;
      y -= 220;
      break;
    case 60:
      x += 235;
      y -= 120;
      break;
    case 75:
      x += 198;
      break;
    default:
      return { dotX: x, dotY: y };
  }
  return { dotX: x, dotY: y };
};

export const decodeValue = (value) => {
  try {
    return atob(value);
  } catch {
    return '';
  }
};

export const setCookie = (name, value, days) => {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString();
};

export const getCookie = (name) => {
  var b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
};

export const deleteCookie = (name) => setCookie(name, '', -1);

export const handleSessionStorage = {
  get: (key) => sessionStorage.getItem(key),
  set: (key, value) => sessionStorage.setItem(key, value)
};

export const getJsCookie = (key) => {
  return Cookies.get(key);
};

export const setJsCookie = (key, value) => {
  return Cookies.set(key, value, { expires: 1 });
};

export const removeJsCookie = (key) => {
  return Cookies.remove(key);
};
