const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: 96,
    color: '#333333',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'unset'
    }
  },
  activityEditWrapper: {
    width: 300,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      width: 423
    }
  },
  avatarEdit: {
    display: 'block',
    color: '#0071ce',
    width: 141,
    height: 20,
    fontSize: 14,
    marginTop: '24px !important'
  },
  avatarSection: {
    width: 380,
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    },
    [theme.breakpoints.up('md')]: {
      width: 262
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%'
    }
  },
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 24,
    [theme.breakpoints.up('md')]: {
      marginTop: 125
    }
  },
  backClick: {
    display: 'flex',
    alignItems: 'center',
    color: '#333333 !important',
    marginLeft: '20px !important',
    '& p': {
      height: 20,
      fontSize: 14,
      marginLeft: 4
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '-70% !important'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '52px !important'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '114px !important',
    [theme.breakpoints.up('sm')]: {
      minWidth: '165px !important'
    }
  },
  icon: {
    display: 'flex',
    justifyContent: 'center'
  },
  iconPadding: {
    marginBottom: 66,
    '&.addExtra': {
      marginBottom: 118
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 32
    }
  },
  profile: {
    width: 328,
    marginLeft: 16,
    marginTop: 36,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      marginTop: 0,
      width: 548
    },
    [theme.breakpoints.up('md')]: {
      width: 536
    },
    [theme.breakpoints.up('lg')]: {
      width: 678
    }
  },
  profileEdit: {
    width: 25,
    height: 20,
    fontSize: 14,
    color: '#0071ce',
    [theme.breakpoints.up('sm')]: {
      marginRight: 32
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 0
    }
  },
  profileEditDate: {
    alignSelf: 'flex-end'
  },
  profileH1: {
    height: 34,
    fontSize: 24,
    fontWeight: 'normal',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  profileH2: {
    height: 50,
    fontSize: 18,
    fontWeight: 300,
    marginTop: 16,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: 25,
      marginTop: 24
    }
  },
  profileH3: {
    width: 328,
    height: 27,
    fontSize: 20,
    marginTop: 52,
    fontWeight: 'normal',
    [theme.breakpoints.up('md')]: {
      marginTop: 64,
      marginRight: 140
    }
  },
  profileH3Name: {
    width: 218,
    height: 25,
    fontSize: 18,
    marginTop: 32,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      width: 248,
      marginTop: 40
    }
  },
  profileH3IconWrap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    marginRight: 13,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      marginTop: 40,
      marginRight: 32
    }
  },
  profileH3Value: {
    width: 218,
    height: 25,
    fontSize: 18,
    fontWeight: 300,
    marginTop: 8,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'end'
    },
    [theme.breakpoints.up('md')]: {
      width: 188,
      marginTop: 40,
      textAlign: 'right'
    }
  },
  profileH3Wrapper: {
    display: 'flex',
    marginBottom: 32,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 25
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 63,
      paddingLeft: 25
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 93
    }
  },
  profileHead: {
    display: 'flex',
    alignItems: 'baseline',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 25
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start'
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 93
    }
  },
  profileRowWrap: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: 456,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      '&.textFieldFlex': {
        flexDirection: 'column'
      }
    }
  },
  profileSave: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 223,
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: 194
    }
  },
  saveButton: {
    fontSize: 14,
    textTransform: 'none'
  },
  snackbarRoot: {
    maxWidth: 444,
    fontSize: 16,
    fontWeight: 500,
    color: '#041e42',
    backgroundColor: '#cce3f5',
    textAlign: 'center'
  },
  textField: {
    width: 288,
    height: 54,
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    marginTop: 32,
    [theme.breakpoints.up('sm')]: {
      height: 54,
      fontSize: 14
    },
    [theme.breakpoints.up('md')]: {
      height: 54
    }
  },
  unsubscribe: {
    fontSize: 14,
    textTransform: 'none !important'
  },
  unsubscribeWrapper: {
    marginTop: 64,
    marginBottom: 74,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      width: 536,
      marginBottom: 0
    },
    [theme.breakpoints.up('lg')]: {
      width: 678,
      marginBottom: 48
    }
  },
  wrapperMdView: {
    [theme.breakpoints.up('md')]: {
      width: 248
    }
  }
});

export default styles;
