const styles = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    [theme.breakpoints.up('sm')]: {
      marginTop: 150
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 56
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 173
    }
  },
  eye: {
    cursor: 'pointer'
  },
  formHolder: {
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 4,
    [theme.breakpoints.up('sm')]: {
      width: 472,
      border: '1px solid #dadce0'
    },
    [theme.breakpoints.up('md')]: {
      width: 426,
      border: '1px solid #dadce0'
    },
    [theme.breakpoints.up('lg')]: {
      width: 586,
      border: '1px solid #dadce0'
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    width: '85%',
    margin: '16px 0px !important',
    height: 48,
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      width: 344
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412,
      height: 54,
      fontSize: 16
    }
  },
  helperText: {
    width: '85%',
    color: '#cb2022',
    fontSize: 14,
    marginBottom: '10px !important',
    [theme.breakpoints.up('sm')]: {
      width: 344
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412
    }
  },
  forgotPassword: {
    fontSize: 14,
    marginTop: 18,
    fontWeight: 500,
    cursor: 'pointer',
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
      marginTop: 14
    },
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  forgotPwdWrapper: {
    width: '85%',
    textAlign: 'right',
    [theme.breakpoints.up('sm')]: {
      width: '73%'
    },
    [theme.breakpoints.up('md')]: {
      width: '70%'
    }
  },
  buttonHolder: {
    display: 'flex',
    width: '85%',
    margin: '32px 0px 48px',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      width: 344
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412
    }
  },
  button: {
    height: 40,
    textTransform: 'none !important',
    [theme.breakpoints.up('sm')]: {
      width: 127
    }
  },
  pwdWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  snackbarRoot: {
    color: '#0089e2',
    backgroundColor: '#e5f0fa',
    fontSize: 16,
    fontWeight: '500',
    maxWidth: 444,
    textAlign: 'center'
  }
});

export default styles;
