import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Link,
  TextField,
  Snackbar
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import OnboardHeader from '../../components/OnboardHeader';
import apiCall from '../../utils/api';
import api from '../../constants/api';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION,
  EMAIL_FIELD_LENGTH,
  ERR_MESSAGES,
  TOAST_MSGS
} from '../../constants/common';
import {
  decodeValue,
  getQueryStringValue,
  isEmptyString,
  isValidEmailId,
  isValidPassword
} from '../../utils/common';
import styles from './styles';

const SignIn = ({ classes }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isError, setIsError] = useState({
    apiError: false,
    email: false,
    pword: false
  });
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [pword, setPword] = useState('');
  const [pwdIsMasked, setPwdIsMasked] = useState(true);
  const [progress, setProgress] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const extractedEmailId = getQueryStringValue(location, 'invite') || '';
    const decodedEmailId = decodeValue(extractedEmailId);
    setEmailId(decodedEmailId);
  }, [location]);

  const signInCall = (event) => {
    event.preventDefault();

    setProgress(true);
    const payload = {
      userId: emailId,
      password: pword
    };
    apiCall(api.signIn, API.METHOD.POST, payload, true).then((response) => {
      setProgress(false);
      if (response?.error) {
        setIsError({ ...isError, apiError: true });
        if (response?.error?.message) {
          if (response?.error?.message.includes(ERR_MESSAGES.INVALID_ACCOUNT)) {
            setErrorMsg(t('errorMsgs.invalid_account_signIn'));
          } else {
            setErrorMsg(response.error.message);
          }
        } else {
          if (response.error.status === API_STATUS_CODE.UNAUTHORIZED) {
            setErrorMsg(ERR_MESSAGES.AUTH_DEFAULT);
          } else {
            setErrorMsg(TOAST_MSGS.generic_error);
          }
        }
      }

      if (response?.status === API_STATUS_CODE.SUCCESS) {
        localStorage.setItem('token', response.data?.authToken);
        localStorage.setItem('refreshToken', response.data?.refreshToken);
        if (location.state === 'termsUseEvent') {
          navigate('/terms-of-use', { state: 'postSignIn' });
        } else if (location.state === 'unsubEvent') {
          navigate('/unsubscribe', { state: 'postSignIn' });
        } else if (location.state === 'privacyPolicy') {
          navigate('/privacy-policy', { state: 'postSignIn' });
        } else if (location.state === 'faqEvent') {
          navigate('/faq', { state: 'postSignIn' });
        } else {
          navigate('/home', { state: 'signIn' });
        }
      }
    });
  };

  const forgotPassword = () => {
    navigate(
      `/forgot-password${
        isValidEmailId(emailId)
          ? `?invite=${btoa(emailId)}&isforgotpasswordflow=true`
          : '?isforgotpasswordflow=true'
      }`
    );
  };

  const togglePasswordMask = () => {
    setPwdIsMasked(!pwdIsMasked);
  };

  const handleEmailChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
    setEmailId(e.target.value?.toLowerCase());
    setIsError({ ...isError, email: false });
    if (!isValidEmailId(e.target.value?.toLowerCase())) {
      setIsError({ ...isError, email: true });
      setErrorMsg(t('errorMsgs.valid_email_pword'));
    }
  };

  const handlePwordChange = (e) => {
    setPword(e.target.value);
    setIsError({ ...isError, pword: false });
    if (!isValidPassword(e.target.value)) {
      setIsError({ ...isError, pword: true });
      setErrorMsg(t('errorMsgs.valid_email_pword'));
    }
  };

  const handleKeyDown = (e) => {
    e.key === ' ' && e.preventDefault();
  };

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <title>Comunidad Spark: {t('signIn.login')}</title>
      <Grid className={classes.formHolder}>
        {progress && (
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isSnackbarOpen}
          message={snackbarMessage}
          autoHideDuration={AUTO_HIDE_DURATION}
          onClose={() => setIsSnackbarOpen(false)}
          ContentProps={{
            classes: {
              root: classes.snackbarRoot
            }
          }}
        />
        <OnboardHeader
          headerText={t('signIn.header1')}
          headerContent={t('signIn.header2')}
        />
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={(event) => signInCall(event)}
        >
          <TextField
            id="email"
            className={classes.textField}
            value={emailId}
            error={isError.email}
            variant="outlined"
            label={t('common.email')}
            onChange={handleEmailChange}
            onKeyDown={handleKeyDown}
            inputProps={{
              maxLength: EMAIL_FIELD_LENGTH
            }}
            data-testid="email"
          />
          <div className={classes.pwdWrapper}>
            <TextField
              id="pword"
              type={pwdIsMasked ? 'password' : 'input'}
              error={isError.pword}
              value={pword}
              className={classes.textField}
              variant="outlined"
              label={t('common.pword')}
              autoComplete="new-password"
              onChange={handlePwordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {pwdIsMasked ? (
                      <RemoveRedEye
                        className={classes.eye}
                        onClick={togglePasswordMask}
                        data-testid="show-password"
                      />
                    ) : (
                      <VisibilityOff
                        className={classes.eye}
                        onClick={togglePasswordMask}
                        data-testid="hide-password"
                      />
                    )}
                  </InputAdornment>
                )
              }}
              FormHelperTextProps={{
                classes: { root: classes.helperText },
                error: isError.email || isError.pword
              }}
              data-testid="pword"
            />
            {(isError.apiError || isError.email || isError.pword) && (
              <p className={classes.helperText} role="alert">
                {errorMsg}
              </p>
            )}
          </div>
          <div className={classes.forgotPwdWrapper}>
            <Link
              id="forgot-password"
              tabIndex="0"
              className={classes.forgotPassword}
              onClick={forgotPassword}
              onKeyPress={forgotPassword}
              data-testid="forgotPwd"
            >
              {t('signIn.forgotPword')}
            </Link>
          </div>
          <div className={classes.buttonHolder}>
            <Button
              id="login"
              variant="contained"
              className={classes.button}
              color="primary"
              type="submit"
              value="Post"
              disabled={
                isEmptyString(emailId) ||
                isEmptyString(pword) ||
                progress ||
                isError.email ||
                isError.pword
              }
            >
              {t('signIn.login')}
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

SignIn.defaultProps = {
  classes: {}
};
SignIn.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(SignIn);
