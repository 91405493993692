import getCurrentEnv from '../utils/env';

const API = {
  DEFAULT_HEADER: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    'Cache-Control': 'no-store'
  },
  HEADER: {
    AUTH_TOKEN: 'WM_SEC.AUTH_TOKEN'
  },
  METHOD: {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
  }
};

export default API;

export const API_STATUS_CODE = {
  UNAUTHORIZED: 401,
  CREATED: 201,
  ACCEPTED: 202,
  EXISTS: 409,
  SUCCESS: 200
};
export const AUTO_HIDE_DURATION = 10000;
export const DATE_DISPLAY_SLASH_FORMAT = 'MM/DD/YY';
export const FOOTER_LINKS = [
  { name: 'Contáctenos', path: 'mailto:ComunidadSpark@walmart.com' },
  {
    name: 'Ayuda',
    path: '/faq?flow=bottomNav',
    url: 'https://one.walmart.com/content/walmart_customer_spark_community/Comunidad-Spark-Preguntas-Frecuentes.html'
  },
  {
    name: 'Privacidad',
    path: '/privacy-policy?flow=bottomNav',
    url: 'https://one.walmart.com/content/walmart_customer_spark_community/Comunidad-Spark-Privacidad.html'
  },
  {
    name: 'Términos y Condiciones',
    path: '/terms-of-use?flow=bottomNav',
    url: 'https://one.walmart.com/content/walmart_customer_spark_community/Comunidad-Spark-Terminos-y-Condiciones.html'
  },
  {
    name: 'Walmart.com.mx',
    path: 'https://super.walmart.com.mx/?_ga=2.198874220.271549804.1670523386-993760368.1633979451'
  },
  {
    name: 'Walmart México y Centroamérica',
    path: 'https://www.walmartmexico.com/'
  },
  {
    name: 'Bodega Aurrera Despensa a tu Casa',
    path: 'https://despensa.bodegaaurrera.com.mx/?gclid=CjwKCAiAs8acBhA1EiwAgRFdw6E8jtDlDdmBeXPx0jTMDqshZB9olDpWbH0lsYnzt-7euPGt5mmvAhoCLgUQAvD_BwE'
  },
  { name: 'Darse de baja', path: '/unsubscribe?flow=bottomNav' }
];

export const GIFT_CARD_OPTIONS = [
  {
    id: 5,
    label: '$5 Gift Card - 500 pts',
    value: '$5 Gift Card',
    disabled: true
  },
  {
    id: 25,
    label: '$25 Gift Card - 2500 pts',
    value: '$25 Gift Card',
    disabled: true
  },
  {
    id: 50,
    label: '$50 Gift Card - 5000 pts',
    value: '$50 Gift Card',
    disabled: true
  },
  {
    id: 100,
    label: '$100 Gift Card - 10000 pts',
    value: '$100 Gift Card',
    disabled: true
  }
];
export const HISTORY_TAB_ITEMS = [
  { display: 'Rewards History', value: 'rewards history' },
  { display: 'Activities History', value: 'activities history' }
];
export const HOME_TAB_ITEMS = [
  { display: 'Actividades', value: 'activities' },
  { display: 'Historial', value: 'history' }
];
export const LIST_SIZE = 20;
export const LIST_TABLE_MAPPING = {
  rewardsHistory: [
    { display: 'Reward Type', field: 'rewardType', type: 'name', width: '20%' },
    { display: 'Points', field: 'points', type: 'points', width: '20%' },
    { display: 'Status', field: 'status', type: 'status', width: '20%' },
    {
      display: 'Redeemed Date',
      field: 'timestamp',
      sort: true,
      type: 'date',
      width: '20%'
    },
    {
      display: 'Reward Details',
      field: 'linkTitle',
      type: 'clickable',
      width: '20%',
      enable: 'showPopup',
      clickableField: 'id'
    }
  ],
  activityHistory: [
    { display: 'Nombre de la actividad', field: 'name', type: 'name' },
    // { display: 'Points', field: 'points', type: 'points' },
    { display: 'Estado', field: 'status', type: 'status' },
    { display: 'Fecha', field: 'timestamp', sort: true, type: 'date' }
  ]
};
export const REGEXP_OTP = /^(?=.*[0-9])(?=.{6,8}$)/;
export const REGEXP_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,30}$)/;

//export const REGEXP_EMAILID = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const REGEXP_EMAILID =
  /^[a-z0-9!'#$%&*+\/=?^_`{|}~-]+(?:\.[a-z0-9!'#$%&*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-zA-Z]{2,}$/;

export const SERVICE_NAMES = [
  { key: 'customer-spark-service', name: 'customer-spark-service' }
];
export const SORT_VALUE = ['desc', 'asc'];
export const TOAST_MSGS = {
  activity_pref_update:
    'Se actualizaron sus preferencias de frecuencia de actividad.',
  forgot_pword_url_error:
    'Copie y pegue correctamente el enlace de contraseña olvidada.',
  generic_error: 'Algo salió mal. Intente después de un tiempo o contáctenos.',
  profile_info_update: 'Se actualizó la información de su perfil.',
  reset_pword_url_error:
    'Ingrese una URL válida o haga clic en cancelar y use Olvidé mi contraseña.',
  reset_pword_click:
    'Le acabamos de enviar un código de autorización único para',
  resend_code_time_limit:
    'Si no recibe nuestro código en unos minutos, haga clic en "Reenviar" para enviar el código a su correo electrónico nuevamente.',
  valid_email_address:
    'Por favor, introduce una dirección de correo electrónico válida.',
  valid_email_pword:
    'Por favor, introduzca un correo electrónico y una contraseña válidos.',
  otp_success_message:
    'Le enviaremos un código de autorización único a {{mailId}}. Si no recibe el código en unos minutos, haga clic en "Reenviar" para enviar el código a su correo electrónico nuevamente.',
  pword_info:
    'Se requiere contraseña segura: de 8 a 30 caracteres. Debe usar una combinación de números, letras (mayúsculas y minúsculas) y símbolos (es decir, #, &, $, %). Consecutivo los caracteres idénticos no pueden ser más de 3.'
};

export const confAgreeContent = {
  CONFIRM_DIALOG: `I understand that by proceeding I will not remain a member of the Walmart Customer Spark Community.`,
  CUST_PORT_URL: `https://comunidadspark.walmart.com/`,
  TERMS_URL: `https://one.walmart.com/content/walmart_customer_spark_community/Comunidad-Spark-Terminos-y-Condiciones.html`,
  PRIV_POLICY_URL: `https://one.walmart.com/content/walmart_customer_spark_community/Comunidad-Spark-Privacidad.html`,
  LABEL_YES: `I agree that I have read and hereby consent to the Spark Community Terms of Use.`,
  LABEL_NO: `I have not read, do not consent, and/or do not understand the Spark Community Terms of Use. I understand that by not consenting to the terms that I will not remain a member of the Walmart Customer Spark Community.`
};

export const unsubContent = {
  TEXT_1: `Are you sure you want to unsubscribe from the Walmart Customer Spark
  Community?`,
  TEXT_2: `Note: If you unsubscribe, you will no longer be a member of the Spark
  Community and you will lose access to the customer portal.`,
  TEXT_3: `Once you unsubscribe, all eligible points (500 or more) will be
  auto-redeemed and any eligible eGift card(s) will be sent to your
  email on file.`
};

export const errorMsg = {
  INVALID_CODE: `Please enter a valid code.`,
  INVALID_PWORD: `Please enter a valid password.`,
  PASS_NO_MATCH: `Passwords do not match.`
};

export const EMAIL_FIELD_LENGTH = 320;

export const ERRORS = {
  IAM: {
    AUTH_CODE: {
      INCORRECT: 'IAM-1033',
      EXPIRED: 'IAM-1034'
    },
    PASSWORD: {
      NEW_OLD_SAME: 'IAM-1015',
      FORMAT_MISMATCH: 'IAM-1000',
      INVALID: 'IAM-1052'
    }
  }
};

export const ERR_MESSAGES = {
  AUTH_CODE_INCORRECT: `Your one-time authorization code is incorrect. In order to proceed, please enter the code again and continue the {%type%} process.`,
  AUTH_CODE_EXPIRED: `Your one-time authorization code has expired. In order to proceed, please resend the code to {%mail%} and continue the {%type%} process.`,
  AUTH_DEFAULT: 'Invalid email and/or password or not a valid account.',
  INVALID_ACCOUNT: `Authentication failed. We cannot find an account with this email address OR the entered email address and password do not match. Please try again. This is an invitation only community.`,
  OLD_PASS_SAME: `New password matches with one of the old passwords. Please use a different password.`
};

export const DOB_MONTH_OPTIONS = [
  {
    id: '01',
    label: 'January',
    value: 'jan'
  },
  {
    id: '02',
    label: 'February',
    value: 'feb'
  },
  {
    id: '03',
    label: 'March',
    value: 'mar'
  },
  {
    id: '04',
    label: 'April',
    value: 'apr'
  },
  {
    id: '05',
    label: 'May',
    value: 'may'
  },
  {
    id: '06',
    label: 'June',
    value: 'jun'
  },
  {
    id: '07',
    label: 'July',
    value: 'jul'
  },
  {
    id: '08',
    label: 'August',
    value: 'aug'
  },
  {
    id: '09',
    label: 'September',
    value: 'sep'
  },
  {
    id: '10',
    label: 'October',
    value: 'oct'
  },
  {
    id: '11',
    label: 'November',
    value: 'nov'
  },
  {
    id: '12',
    label: 'December',
    value: 'dec'
  }
];

export const LANDING_PAGE_CONTENT = {
  Q1: `¿Qué es la comunidad Spark de clientes de Walmart?`,
  A1: `Una comunidad de clientes Walmart como tú, de todo el país. La comunidad Spark de clientes Walmart ofrece a los miembros una variedad de actividades para brindar comentarios sobre productos, servicios y más. Nuestro objetivo es continuar haciendo de ti, el cliente, el #1 al escuchar tus opiniones.`,
  Q2: `¿Cómo funciona la comunidad Spark de clientes de Walmart?`,
  A2: `La comunidad Spark de clientes de Walmart es solo por invitación. Cuando los clientes son invitados, se unen y pueden participar en nuestras actividades. ¡Es así de fácil`
};

export const LANDING_FAQ_CONTENT = [
  {
    question: `¿Qué es la Comunidad Spark de clientes de Walmart?`,
    answer: `La Comunidad Spark de clientes de Walmart es una comunidad solo por invitación donde los miembros tienen la oportunidad de participar en encuestas, entrevistas, otras actividades y más.`
  },
  // {
  //   question: `How does the Walmart Customer Spark Community work?`,
  //   answer: `When customers complete activities, they earn points redeemable for Walmart eGift cards.`
  // },
  {
    question: `¿Me puedo unir a la Comunidad Spark de clientes de Walmart?`,
    answer: `En este momento, la Comunidad Spark es una comunidad solo por invitación. Si es seleccionado para participar, recibirá un correo electrónico nuestro para obtener más información.`
  },
  // {
  //   question: `Is this the Spark Reviewer program?`,
  //   answer: `Though you may be asked to provide product reviews, we are not the Spark Reviewer Program. The Walmart Customer Spark Community provides you with activities beyond only reviews to engage with us and earn points.`
  // },
  {
    question: `¿Cómo me doy de baja?`,
    answer: `Puede darse de baja de la Comunidad Spark a través del enlace ‘Cancelar suscripción’ en el portal de clientes de la Comunidad Spark o desde cualquier correo electrónico de la Comunidad Spark,`
  },
  {
    question: `Tengo una pregunta o comentario sobre una experiencia reciente o un pedido en Walmart. ¿Puede usted ayudar?`,
    answer: `Para asegurarse de que el equipo apropiado pueda ayudar lo más rápido posible, visite la página del Centro de ayuda de Walmart {%helpCenter%}`
  },
  {
    question: `¿Cuál es la Política de privacidad de Walmart?`,
    answer: `Walmart tiene información detallada en su página de Política de privacidad {%privacy%}`
  },
  {
    question: `Tengo preguntas sobre la Comunidad de Clientes Spark de Walmart que no se tratan aquí. ¿Puede usted ayudar?`,
    answer: `¡Sí! Puede comunicarse con nosotros directamente en ComunidadSpark@walmart.com.`,
    note: `Nota: si usted ya es miembro, inicie sesión primero para revisar las preguntas frecuentes adicionales que podrían responder su pregunta.`
  }
];

export const LANDING_FAQ_LINKS = {
  HELP: 'https://www.walmart.com.mx/contenido/centro-de-ayuda/tus-pedidos',
  PRIVACY:
    'https://one.walmart.com/content/walmart_customer_spark_community/Comunidad-Spark-Privacidad.html'
};

export const LANDING_REVIEW_CONTENT = [
  {
    avatar: `customer-spark-external-male-person-1`,
    heading: `From Texas`,
    review: `“I feel appreciated as an important customer. And, I am rewarded for my participation!”`
  },
  {
    avatar: `customer-spark-external-male-person-4`,
    heading: `From Massachusetts`,
    review: `“Walmart cares about its customer experience and gives the opportunity to Spark members to give opinions.”`
  },
  {
    avatar: `customer-spark-external-female-person-2`,
    heading: `From Ohio`,
    review: `“It is fun, interesting and most of all you get voice your honest opinions and feedback!!”`
  },
  {
    avatar: `customer-spark-external-female-person-3`,
    heading: `From Washington`,
    review: `“Surveys are relatively easy to complete and the rewards are fair considering the amount of time involved.” `
  },
  {
    avatar: `customer-spark-external-female-person-5`,
    heading: `From California`,
    review: `“I enjoy giving my opinion about products and things going on in the community. I don't feel it takes too much of my time at all.”`
  },
  {
    avatar: `customer-spark-external-male-person-6`,
    heading: `From Ohio`,
    review: `“Getting rewarded with points that earn money at for weekly shopping trip is fantastic!”`
  },
  {
    avatar: `customer-spark-external-female-person-7`,
    heading: `From North Carolina`,
    review: `“I feel that my opinion is valued,and I get to make recommendations for items,or services that I would like to have available at my local Walmart.”`
  },
  {
    avatar: `customer-spark-external-female-person-9`,
    heading: `From Oklahoma`,
    review: `“The Spark Community is awesome!!”`
  },
  {
    avatar: `customer-spark-external-female-person-10`,
    heading: `From New York`,
    review: `“Surveys are easy and time efficient.”`
  },
  {
    avatar: `customer-spark-external-male-person-8`,
    heading: `From Virginia`,
    review: `“It gives me an opportunity to contribute to future decisions made to stock and functions of a store I shop often.”`
  }
];

export const LANDING_VIDEO_ID =
  getCurrentEnv() === 'prod'
    ? {
        hero: '799028524/91ceda029a',
        whatCS: '799028612/4814f36e37',
        howCS: '799028570/dd0e8d20b2'
      }
    : {
        hero: '799028524/91ceda029a',
        whatCS: '799028612/4814f36e37',
        howCS: '799028570/dd0e8d20b2'
      };

export const PROFILE_ACCORDION_DATA = [
  {
    type: 'personInfo',
    summary: 'Personal Information',
    details: [
      { name: 'Screen name', key: 'screenName' },
      { name: 'Birth month & year', key: 'dateOfBirth' },
      { name: 'Education', key: 'education' },
      { name: 'Employment', key: 'employment' },
      { name: 'Zip Code', key: 'zipCode' }
    ]
  },
  {
    type: 'householdInfo',
    summary: 'Household Information',
    details: [
      { name: 'Decision making', key: 'decisionMaking' },
      { name: 'Pet ownership', key: 'petOwned' },
      { name: 'Walmart Health', key: 'wmHealth' },
      { name: 'Family pharmacy', key: 'familyPharmacy' }
    ]
  },
  {
    type: 'shopping',
    summary: 'Shopping',
    details: [{ name: 'Retail memberships', key: 'retailMembership' }]
  },
  {
    type: 'activityPref',
    summary: 'Activity Preferences',
    details: [
      { name: 'Activity frequency', key: 'actFrequency' },
      { name: 'Surveys', key: 'surveys' },
      { name: 'Video activities', key: 'videoActs' },
      { name: 'Other', key: 'other' }
    ]
  }
];

export const PROFILE_OPTIONS_LIST = [
  {
    type: 'decisionMaking',
    options: [
      {
        id: 1,
        label: 'I do all or most of the decision making',
        value: 1
      },
      {
        id: 2,
        label: 'I equally share the decision making with someone else',
        value: 2
      },
      {
        id: 3,
        label: 'Someone else makes all or most of the decisions',
        value: 3
      }
    ]
  },
  {
    type: 'education',
    options: [
      {
        id: 1,
        label: 'Some high school or less',
        value: 1
      },
      {
        id: 2,
        label: 'Graduated high school',
        value: 2
      },
      {
        id: 3,
        label: 'Some college or technical school',
        value: 3
      },
      {
        id: 4,
        label: 'Graduated college or technical school',
        value: 4
      },
      {
        id: 5,
        label: 'Post graduate degree',
        value: 5
      },
      {
        id: 6,
        label: 'Prefer not to answer',
        value: 6
      }
    ]
  },
  {
    type: 'employment',
    options: [
      {
        id: 1,
        label: 'Employed full time',
        value: 1
      },
      {
        id: 2,
        label: 'Employed part time',
        value: 2
      },
      {
        id: 3,
        label: 'Full time homemaker',
        value: 3
      },
      {
        id: 4,
        label: 'Full time student',
        value: 4
      },
      {
        id: 5,
        label: 'Part time student',
        value: 5
      },
      {
        id: 6,
        label: 'Retired',
        value: 6
      },
      {
        id: 7,
        label: 'Not currently working',
        value: 7
      },
      {
        id: 8,
        label: 'Prefer not to answer',
        value: 8
      }
    ]
  },
  {
    type: 'familyPharmacy',
    options: [
      {
        id: 1,
        label: 'Walmart',
        value: 1
      },
      {
        id: 2,
        label: 'Walgreens',
        value: 2
      },
      {
        id: 3,
        label: 'CVS',
        value: 3
      },
      {
        id: 4,
        label: `Warehouse (ex. Sam's Club, Costco)`,
        value: 4
      },
      {
        id: 5,
        label: 'A local pharmacy',
        value: 5
      },
      {
        id: 6,
        label: 'Other',
        value: 6
      },
      {
        id: 7,
        label: 'Prefer not to answer',
        value: 7
      }
    ]
  },
  {
    type: 'petOwned',
    options: [
      {
        id: 1,
        label: 'Dog',
        value: 1
      },
      {
        id: 2,
        label: 'Cat',
        value: 2
      },
      {
        id: 3,
        label: 'Reptile',
        value: 3
      },
      {
        id: 4,
        label: 'Rodent',
        value: 4
      },
      {
        id: 5,
        label: 'Fish',
        value: 5
      },
      {
        id: 6,
        label: 'Other',
        value: 6
      },
      {
        id: 7,
        label: 'None of the above',
        value: 7
      }
    ]
  },
  {
    type: 'retailMembership',
    options: [
      {
        id: 1,
        label: 'Target',
        value: 1
      },
      {
        id: 2,
        label: 'Amazon',
        value: 2
      }
    ]
  },
  {
    type: 'wmHealth',
    options: [
      {
        id: 1,
        label: 'Yes',
        value: 1
      },
      {
        id: 2,
        label: 'No',
        value: 2
      }
    ]
  }
];

export const PROFILE_SELECT_FIELDS = [
  'decisionMaking',
  'education',
  'employment',
  'familyPharmacy',
  'petOwned',
  'retailMembership',
  'wmHealth'
];

export const PROFILE_SWITCH_FIELDS = ['surveys', 'videoActs', 'other'];

export const ACCOUNT_ACCORDION_DATA = [
  {
    type: 'manageAccount',
    summary: 'Manage Account',
    details: [
      {
        name: 'Profile',
        display: 'Personal, Household, Shopping Information',
        key: 'profile'
      },
      {
        name: 'Activity Preferences',
        display: 'Manage activity frequency',
        key: 'activityPref'
      },
      {
        name: 'Notifications',
        display: 'Manage what alerts you receive',
        key: 'notify'
      },
      {
        name: 'Unsubscribe',
        display: 'Manage your subscription status',
        key: 'unsubscribe'
      }
    ]
  },
  {
    type: 'resources',
    summary: 'Resources',
    details: [
      {
        name: 'Frequently Asked Questions',
        display: 'Learn more about the Customer Spark Community',
        key: 'faq'
      },
      {
        name: 'Help',
        display: 'Contact us if you have any questions',
        key: 'help'
      },
      {
        name: 'Privacy Policy',
        display: 'Customer Spark Community’s Privacy Policy',
        key: 'privacy'
      },
      {
        name: 'Terms of Use',
        display: 'Customer Spark Community’s Term of Use',
        key: 'terms'
      },
      {
        name: 'Rewards',
        display: 'Find more information about rewards ',
        key: 'rewards'
      },
      {
        name: 'Walmart.com',
        display: 'Save Money Live Better',
        key: 'walmart'
      }
    ]
  },
  {
    type: 'privacy',
    summary: 'Privacy',
    details: [
      {
        name: 'Privacy & Security',
        display: '',
        key: 'privSec'
      },
      {
        name: 'Don’t sell my personal information',
        display: '',
        key: 'dontSellPi'
      },
      {
        name: 'Request my personal information',
        display: '',
        key: 'requestPi'
      }
    ]
  }
];

export const AVATARS = [
  {
    id: 1,
    level: 0,
    path: '/assets/images/profile-avatars/Comunidad-Spark-Avatars-Headphones.svg'
  },
  {
    id: 2,
    level: 0,
    path: '/assets/images/profile-avatars/Comunidad-Spark-Avatars-Yoga.svg'
  },
  {
    id: 3,
    level: 0,
    path: '/assets/images/profile-avatars/Comunidad-Spark-Avatars-Plant.svg'
  },
  {
    id: 4,
    level: 0,
    path: '/assets/images/profile-avatars/Comunidad-Spark-Avatars-Pineapple.svg'
  },
  {
    id: 5,
    level: 0,
    path: '/assets/images/profile-avatars/Comunidad-Spark-Avatars-Conchas.svg'
  }
];

// export const PASSWORD_RULES = [
//   {
//     title: 'Include 8 to 30 characters',
//     rule: () => new RegExp('^(?=.{8,30}$)').test(pword)
//   },
//   {
//     title: 'Include at least one number',
//     rule: () => new RegExp('^(?=.*[0-9])').test(pword)
//   },
//   {
//     title: 'Include at least one upper case letter',
//     rule: () => new RegExp('^(?=.*[A-Z])').test(pword)
//   },
//   {
//     title: 'Include at least one lower case letter',
//     rule: () => new RegExp('^(?=.*[a-z])').test(pword)
//   },
//   {
//     title: 'Include at least one symbol (ie: #, &, $, %)',
//     rule: () => new RegExp('^(?=.*[!@#$%^&*])').test(pword)
//   },
//   {
//     title: 'Cannot contain more than 3 consecutive identical characters',
//     rule: () => !new RegExp('^(?=(.)+\\1\\1\\1)').test(pword)
//   }
// ];
