const styles = (theme) => ({
  buttonHolder: {
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    display: 'flex',
    width: '85%',
    marginTop: 24,
    marginBottom: 8,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 40,
      width: 344,
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412
    }
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
    borderRadius: 4,
    [theme.breakpoints.up('sm')]: {
      marginTop: '5%'
    }
  },
  eye: {
    cursor: 'pointer'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  formHolder: {
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 4,
    [theme.breakpoints.up('sm')]: {
      width: 472,
      border: '1px solid #dadce0'
    },
    [theme.breakpoints.up('md')]: {
      width: 426
    },
    [theme.breakpoints.up('lg')]: {
      width: 586
    }
  },
  genCodebutton: {
    height: 40,
    borderRadius: 4,
    textTransform: 'none !important',
    minWidth: 122
  },
  helperText: {
    width: '85%',
    color: '#cb2022',
    fontSize: 14,
    marginBottom: '10px !important',
    [theme.breakpoints.up('sm')]: {
      width: 344
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412
    }
  },
  passwordRulesHolder: {
    width: 312,
    marginBottom: 26,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      display: 'block',
      position: 'absolute',
      left: '75%'
    }
  },
  pwdWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  resetButton: {
    width: '100%',
    height: 40,
    fontSize: 14,
    lineHeight: 'normal',
    margin: '8px 0px',
    textTransform: 'none !important',
    [theme.breakpoints.up('sm')]: {
      width: 175,
      margin: 0
    }
  },
  cancelButton: {
    width: '100%',
    height: 40,
    fontSize: 14,
    lineHeight: 'normal',
    margin: '8px 0',
    textTransform: 'none !important',
    [theme.breakpoints.up('sm')]: {
      margin: '0 32px 0 0',
      width: 90
    }
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  resendCode: {
    fontSize: 16,
    color: '#0071ce',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
      color: '#0071ce',
      '&.blockOtp': {
        cursor: 'not-allowed',
        textDecoration: 'none',
        color: '#545355'
      }
    }
  },
  blockOtp: {
    color: '#545355 !important'
  },
  resendCodeWrapper: {
    width: '85%',
    textAlign: 'right',
    margin: '24px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      width: '73%'
    },
    [theme.breakpoints.up('md')]: {
      width: '70%'
    }
  },
  snackbar: {
    color: '#0089e2',
    backgroundColor: '#e5f0fa',
    fontSize: 16,
    fontWeight: '500',
    maxWidth: 444,
    textAlign: 'center'
  },
  textField: {
    width: '85%',
    margin: '16px 0px !important',
    height: 48,
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      width: 344,
      fontSize: 14
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412,
      height: 54,
      fontSize: 16
    }
  },
  mainBold: {
    alignItems: 'baseline'
  },
  marginWrap: {
    margin: '0 0 24px 0'
  },
  passInfo: {
    width: '85%',
    margin: '0 16px 16px !important',
    fontSize: '12px !important',
    textAlign: 'center',
    color: '#414042',
    [theme.breakpoints.up('sm')]: {
      width: '74%'
    }
  }
});

export default styles;
