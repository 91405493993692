const styles = (theme) => ({
  backDrops: {
    backgroundColor: '#ffffffcc'
  },
  paper: {
    '&:first-child': {
      paddingTop: 32
    },
    '&:last-child': {
      paddingBottom: 24
    }
  },
  title: {
    paddingTop: 0,
    paddingBottom: 0
  },
  actions: {
    padding: '8px 24px'
  },
  btn: {
    textTransform: 'none !important'
  },
  content: {
    fontSize: 16,
    lineHeight: 1.25
  },
  confirm: {
    fontSize: 14,
    maxWidth: 280,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 440
    }
  }
});

export default styles;
