import React, { useEffect, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import './index.scss';

const AppWithCallbackAfterRender = () => {
  useEffect(() => {
    console.log('rendered');
  });

  return (
    <Suspense fallback="...is loading">
      <App />
    </Suspense>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
