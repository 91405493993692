/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import {
  AccountCircle,
  ViewHeadline,
  ViewListOutlined
} from '@mui/icons-material';
import { withStyles } from '@mui/styles';

import styles from './styles';

const BottomNav = ({ classes, handleTab, resetInnerTab, user }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('activities');
  // const [userProfile, setUserProfile] = useState(null);

  const handleClick = e => {
    e.preventDefault();

    const tab = e.currentTarget.textContent.toLowerCase();

    handleTab(tab);
    setActiveTab(tab);
    resetInnerTab(true);
  };

  // const openUserProfile = (event) => {
  //   setUserProfile(event.currentTarget);
  // };

  // const closeUserProfile = () => {
  //   setUserProfile(null);
  // };

  // const userOpen = Boolean(userProfile);
  // const userId = userOpen ? 'user-detail' : undefined;

  const handleAccount = () => {
    navigate('/profile');
  };

  return (
    <div className={classes.layout}>
      {/* <Link
        component="button"
        onClick={handleClick}
        className={`${classes.iconName} ${
          activeTab === 'rewards' ? 'active' : ''
        }`}
        underline="none"
        data-testid={'rewards'}
      >
        <RedeemOutlined className={classes.icon} />
        Rewards
      </Link> */}
      <Link
        component="button"
        onClick={handleClick}
        className={`${classes.iconName} ${
          activeTab === 'activities' ? 'active' : ''
        }`}
        underline="none"
        data-testid={'activities'}
      >
        <ViewListOutlined className={classes.icon} />
        Activities
      </Link>
      <Link
        component="button"
        onClick={handleClick}
        className={`${classes.iconName} ${
          activeTab === 'history' ? 'active' : ''
        }`}
        underline="none"
        data-testid={'history'}
      >
        <ViewHeadline className={classes.icon} />
        History
      </Link>
      <Link
        component="button"
        onClick={handleAccount}
        className={`${classes.iconName} ${
          activeTab === 'account' ? 'active' : ''
        }`}
        underline="none"
        data-testid={'account'}
      >
        <AccountCircle className={classes.icon} />
        Account
      </Link>
      {/* <Link
        component="button"
        onClick={openUserProfile}
        className={classes.iconName}
        underline="none"
        id={userId}
        data-testid={'profile'}
      >
        <MoreHoriz className={classes.icon} />
        More
        <Popover
          id={userId}
          open={userOpen}
          anchorEl={userProfile}
          onClose={closeUserProfile}
          onBlur={closeUserProfile}
          anchorOrigin={getOrigin('bottom', 'left')}
          transformOrigin={getOrigin('top', 'center')}
          data-testid={'profile-pop'}
        >
          <Card
            className={`${classes.typography} ${classes.center} ${classes.card}`}
            role="dialog"
          >
            <Button
              id="profile"
              className={classes.btnStyle}
              onClick={handleProfile}
              data-testid={'profile'}
            >
              View Profile
            </Button>
            <Button
              id="logout"
              className={classes.btnStyle}
              onClick={logout}
              data-testid={'logout'}
            >
              Log Out
            </Button>
          </Card>
        </Popover>
      </Link> */}
    </div>
  );
};

BottomNav.defaultProps = {
  classes: {}
};

BottomNav.propTypes = {
  classes: PropTypes.object,
  handleTab: PropTypes.func.isRequired,
  resetInnerTab: PropTypes.func.isRequired,
  user: PropTypes.array.isRequired
};

export default withStyles(styles)(BottomNav);
