import React from 'react';
import Iframe from '../../components/Iframe';
import { FOOTER_LINKS } from '../../constants/common';

const PrivacyPolicy = () => {
  return (
    <Iframe
      url={FOOTER_LINKS[2]?.url}
      title="Privacy Policy"
      type="privacyPolicy"
    />
  );
};

export default PrivacyPolicy;
