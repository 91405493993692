import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Link,
  Snackbar,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { RemoveRedEye, VisibilityOff, CheckCircle } from '@mui/icons-material';
import OkCancel from '../../components/AlertDialog/OkCancel';
import OnboardHeader from '../../components/OnboardHeader';
import PasswordRulesCheck from '../../components/PasswordRulesCheck';
import api from '../../constants/api';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION,
  TOAST_MSGS,
  errorMsg,
  ERR_MESSAGES
} from '../../constants/common';
import apiCall from '../../utils/api';
import {
  decodeValue,
  handleSessionStorage,
  isEmptyString,
  isValidEmailId,
  isValidOtp,
  isValidPassword,
  maskEmailId,
  getQueryStringValue
} from '../../utils/common';
import styles from './styles';

const ResetPassword = ({ classes }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const initAlertBox = {
    open: false,
    primaryBtn: '',
    handleBtnClick: () => {},
    title: '',
    content: '',
    code: ''
  };

  const [alertBox, setAlertBox] = useState(initAlertBox);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disableOtp, setDisableOtp] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState('');
  const [otpToken, setOtpToken] = useState('');
  const [pword, setPword] = useState('');
  const [pwdIsMasked, setPwdIsMasked] = useState(true);
  const [progress, setProgress] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const extractedEmailId = getQueryStringValue(location, 'invite') || '';
    const decodedEmailId = decodeValue(extractedEmailId);
    setEmailId(decodedEmailId);
    if (!isValidEmailId(decodedEmailId)) {
      setIsSnackbarOpen(true);
      setSnackbarMessage(TOAST_MSGS.reset_pword_url_error);
    }
  }, [location]);

  useEffect(() => {
    const forgotPassFlag = getQueryStringValue(
      location,
      'isforgotpasswordflow'
    );
    const isForgotPwdFlow = forgotPassFlag === null ? 'false' : forgotPassFlag;

    if (isValidEmailId(emailId)) {
      if (
        !handleSessionStorage.get('refreshLoad') &&
        isForgotPwdFlow !== 'true'
      ) {
        setAlertBox({
          open: true,
          primaryBtn: t('link.ok'),
          handleBtnClick: sendOtpCall,
          content: `${t('forgotPass.subHeader', {
            mailId: maskEmailId(emailId)
          })}. ${t('forgotPass.otpSuccess')}`
        });
      }
      handleSessionStorage.set('refreshLoad', true);
    }
  }, [emailId, location]);

  const sendOtpCall = () => {
    setAlertBox(initAlertBox);
    setDisableOtp(true);

    const payload = {
      userId: emailId,
      registration:
        getQueryStringValue(location, 'isforgotpasswordflow') === 'false'
    };

    setProgress(true);
    apiCall(api.sendOtp, API.METHOD.POST, payload, true).then((response) => {
      setProgress(false);
      setTimeout(() => setDisableOtp(false), 60000);
      if (response?.error) {
        setIsSnackbarOpen(true);
        setSnackbarMessage(response?.error?.message);
      }

      if (response?.status === API_STATUS_CODE.SUCCESS) {
        navigate(
          `/reset-password/?invite=${btoa(emailId)}&isforgotpasswordflow=false`
        );
      }
    });
  };

  const cancel = () => {
    navigate('/login');
  };

  const transformError = (errorMsg) => {
    if (errorMsg.includes('password code is incorrect.')) {
      return `${t('errorMsgs.invalid_otp_reset')} ${
        getQueryStringValue(location, 'isforgotpasswordflow') === 'true'
          ? t('common.reset_pword')
          : t('common.register').toLowerCase()
      }.`;
    } else if (errorMsg.includes('password code has expired.')) {
      return `${t('errorMsgs.expired_otp_reset').replace(
        '{%mail%}',
        maskEmailId(emailId)
      )} ${
        getQueryStringValue(location, 'isforgotpasswordflow') === 'true'
          ? t('common.reset_pword')
          : t('common.register').toLowerCase()
      }.`;
    } else if (errorMsg.includes(ERR_MESSAGES.OLD_PASS_SAME)) {
      return t('errorMsgs.old_new_same_pword');
    }
    return errorMsg;
  };

  const resetPasswordCall = (event) => {
    event.preventDefault();

    if (pword && isValidPassword(pword) && pword === confirmPassword) {
      setProgress(true);
      const payload = {
        userId: emailId,
        password: pword,
        otp: otpToken
      };

      apiCall(`${api.resetPassword}`, API.METHOD.POST, payload, true).then(
        (response) => {
          setProgress(false);
          if (response?.error) {
            setAlertBox({
              code: 1,
              title: t('resetPwd.failTitle'),
              open: true,
              primaryBtn: t('link.continue'),
              content: transformError(response?.error?.message)
            });
          }

          if (response?.status === API_STATUS_CODE.SUCCESS) {
            setAlertBox({
              code: 0,
              title: t('resetPwd.successTitle'),
              open: true,
              primaryBtn: t('link.ok'),
              content: t('resetPwd.successContent')
            });
          }
        }
      );
    }
  };

  const isPasswordValid = () =>
    pword && isValidPassword(pword) && pword === confirmPassword;

  const togglePasswordMask = () => {
    setPwdIsMasked(!pwdIsMasked);
  };

  const handleConfirmModalClose = () => {
    if (alertBox.code) {
      setAlertBox(initAlertBox);
      if (alertBox.content.includes('código de autorización único')) {
        setOtpToken('');
      } else {
        setPword('');
        setConfirmPassword('');
      }
    } else {
      const param = isValidEmailId(emailId) ? `?invite=${btoa(emailId)}` : '';
      navigate(`/login${param}`);
    }
  };

  return (
    <Grid className={classes.container}>
      <title>Comunidad Spark: {t('common.reset_pword')}</title>
      <Grid className={classes.formHolder}>
        {progress && (
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isSnackbarOpen}
          message={snackbarMessage}
          autoHideDuration={AUTO_HIDE_DURATION}
          onClose={() => setIsSnackbarOpen(false)}
          ContentProps={{
            classes: {
              root: classes.snackbar
            }
          }}
        />
        <OnboardHeader
          headerText={
            getQueryStringValue(location, 'isforgotpasswordflow') === 'true'
              ? `${t('common.reset')} ${t('common.pword')?.toLowerCase()}`
              : t('forgotPass.regHeader')
          }
          headerContent={t('forgotPass.provideInfo')}
        />
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={resetPasswordCall}
        >
          <div className={classes.pwdWrapper}>
            <TextField
              id="otpCode"
              className={classes.textField}
              error={otpToken && !isValidOtp(otpToken)}
              variant="outlined"
              label={t('forgotPass.enterCode')}
              value={otpToken}
              onChange={(event) => setOtpToken(event.target.value)}
              data-testid="enter-code"
            />
            {!isEmptyString(otpToken) && !isValidOtp(otpToken) && (
              <p className={classes.helperText} role="alert">
                {t('errorMsgs.invalid_code')}
              </p>
            )}
          </div>
          <div className={`${classes.resendCodeWrapper} ${classes.marginWrap}`}>
            <Typography className={classes.mainBold}>
              {t('forgotPass.noCode')}&nbsp;
              <Tooltip title={t('forgotPass.resendCode')} placement="top">
                <Link
                  component="button"
                  onClick={sendOtpCall}
                  className={`${classes.resendCode} ${
                    disableOtp ? 'blockOtp' : ''
                  }`}
                  underline="hover"
                  disabled={disableOtp}
                  data-testid="resend"
                >
                  {t('common.resend')}
                </Link>
              </Tooltip>
            </Typography>
          </div>
          <Typography className={classes.passInfo}>
            {t('forgotPass.passReq')}
          </Typography>
          <TextField
            id="pword"
            error={pword && !isValidPassword(pword)}
            type={pwdIsMasked ? 'password' : 'input'}
            autoComplete="new-password"
            className={classes.textField}
            variant="outlined"
            label={`${t('common.new')} ${t('common.pword')?.toLowerCase()}`}
            onChange={(event) => setPword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {pwdIsMasked ? (
                    <RemoveRedEye
                      className={classes.eye}
                      onClick={togglePasswordMask}
                      data-testid="show-eye"
                    />
                  ) : (
                    <VisibilityOff
                      className={classes.eye}
                      onClick={togglePasswordMask}
                      data-testid="hide-eye"
                    />
                  )}
                </InputAdornment>
              )
            }}
            value={pword}
            data-testid="new-password"
          />
          <div className={classes.pwdWrapper}>
            <TextField
              id="confirmPwd"
              error={confirmPassword && pword !== confirmPassword}
              type="password"
              className={`${classes.textField} ${
                confirmPassword && pword !== confirmPassword
                  ? classes.helperText
                  : ''
              }`}
              variant="outlined"
              label={`${t('link.confirm')} ${t(
                'common.new'
              )?.toLowerCase()} ${t('common.pword')?.toLowerCase()}`}
              onChange={(event) => setConfirmPassword(event.target.value)}
              value={confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {pword && pword === confirmPassword ? (
                      <CheckCircle color="primary" />
                    ) : (
                      ''
                    )}
                  </InputAdornment>
                )
              }}
              data-testid="confirm-password"
            />
            {!isEmptyString(pword) &&
              !isValidPassword(pword) &&
              !confirmPassword && (
                <p className={classes.helperText} role="alert">
                  {t('errorMsgs.invalid_pword')}
                </p>
              )}
            {confirmPassword && pword !== confirmPassword && (
              <p className={classes.helperText} role="alert">
                {t('errorMsgs.pass_no_match')}
              </p>
            )}
          </div>
          <div className={classes.buttonHolder}>
            <Button
              id="rp-cancel"
              variant="text"
              className={classes.cancelButton}
              color="primary"
              value="cancel"
              onClick={cancel}
            >
              {t('link.cancel')}
            </Button>
            <Button
              id="reset-pwd"
              variant="contained"
              className={classes.resetButton}
              color="primary"
              type="submit"
              value="Post"
              disabled={progress || !isPasswordValid() || !isValidOtp(otpToken)}
              data-testid={'submitBtn'}
            >
              {getQueryStringValue(location, 'isforgotpasswordflow') === 'true'
                ? `${t('common.reset')} ${t('common.pword')?.toLowerCase()}`
                : t('common.register')}
            </Button>
          </div>
        </form>
      </Grid>
      <OkCancel
        open={alertBox.open}
        title={alertBox.title}
        primaryBtnClick={alertBox.handleBtnClick || handleConfirmModalClose}
        content={alertBox.content || ''}
        primaryBtnText={alertBox.primaryBtn}
        isDisabled={progress}
      />
      {pword && (
        <Grid className={classes.passwordRulesHolder}>
          <PasswordRulesCheck pword={pword} />
        </Grid>
      )}
    </Grid>
  );
};

ResetPassword.defaultProps = {
  classes: {}
};

ResetPassword.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ResetPassword);
