const styles = (theme) => ({
  wrapper: {
    minWidth: '100vw',
    marginBottom: 20,
    zIndex: 2,
    marginTop: 140,
    color: '#3a3a3a',
    background: '#fff',
    borderTop: 'solid 1px #dadce0',
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      marginTop: '174px !important'
    }
  },
  link: {
    marginRight: '10px !important',
    [theme.breakpoints.up('sm')]: {
      //marginRight: 34
    },
    [theme.breakpoints.up('md')]: {
      //marginRight: 21
    }
  },
  linkWrapper: {
    width: '90% !important',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: '32px 0 24px !important',
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      width: '80% !important'
    },
    [theme.breakpoints.up('lg')]: {
      width: '70% !important'
    }
  },
  copyright: {
    textAlign: 'center',
    fontSize: '12px !important',
    [theme.breakpoints.up('md')]: {
      //marginLeft: 270
    },
    [theme.breakpoints.up('lg')]: {
      //marginLeft: 470
    }
  }
});

export default styles;
