import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Grid, Grow, Link, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { useWindowSize } from '../../hooks';
import HomeFooter from '../../components/Footer/HomeFooter';
import Logo from '../../components/Logo';
import VideoPlayer from '../../components/VideoPlayer';
import {
  LANDING_FAQ_CONTENT,
  LANDING_FAQ_LINKS,
  LANDING_PAGE_CONTENT,
  LANDING_VIDEO_ID
} from '../../constants/common';
import styles from './styles';

const Landing = ({ classes }) => {
  const {
    contentBody,
    contentHead,
    faqLink,
    faqWrapper,
    gridContainer,
    headVideoWrapper,
    note,
    qsContainer,
    qsHead,
    qsWrapper,
    seeMore,
    videoWrapper
  } = classes;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [offset, setOffset] = useState(0);
  const [readMore, setReadMore] = useState(false);
  const [autoPlayObject, setAutoPlayObj] = useState({
    landing: true,
    whatCS: false,
    howCS: false
  });

  const updateAutoPlay = () => {
    if (offset <= 250) {
      setAutoPlayObj({ landing: true, whatCS: false, howCS: false });
    } else if (width <= 600 && offset > 250 && offset <= 550) {
      setAutoPlayObj({ landing: false, whatCS: true, howCS: false });
    } else if (width > 600 && offset > 250 && offset <= 500) {
      setAutoPlayObj({ landing: false, whatCS: true, howCS: false });
    } else {
      setAutoPlayObj({ landing: false, whatCS: false, howCS: true });
    }
  };

  useEffect(() => {
    window.onscroll = (e) => {
      e.preventDefault();
      setOffset(window.pageYOffset);
    };
    updateAutoPlay();
  }, [offset]);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSeeMore = () => {
    setReadMore(!readMore);
  };

  const getFaqLinks = (text) => {
    const data = {
      text: '',
      link: ''
    };
    if (text.includes('{%helpCenter%}')) {
      return {
        ...data,
        text: text.replace('{%helpCenter%}', ''),
        link: LANDING_FAQ_LINKS.HELP
      };
    } else if (text.includes('{%privacy%}')) {
      return {
        ...data,
        text: text.replace('{%privacy%}', ''),
        link: LANDING_FAQ_LINKS.PRIVACY
      };
    } else {
      return { ...data, text };
    }
  };

  const getFaqContent = () =>
    LANDING_FAQ_CONTENT.map((faq, index) => {
      const content = (
        <>
          <Typography className={classNames(contentHead, 'smallHead')}>
            {faq.question}
          </Typography>
          <Typography className={contentBody}>
            {getFaqLinks(faq.answer).text}
            {(index === 3 || index === 4) && (
              <>
                <Link
                  href={getFaqLinks(faq.answer).link}
                  className={faqLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  underline="always"
                >
                  {t('common.here')}
                </Link>
                <span>.</span>
              </>
            )}
          </Typography>
          {faq.note && <Typography className={note}>{faq.note}</Typography>}
        </>
      );
      if (index <= 2 && !readMore) {
        return content;
      } else if (readMore) {
        return content;
      }
    });

  const getSeeMoreLink = () => (
    <Link
      className={seeMore}
      onClick={handleSeeMore}
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
    >
      Ver {!readMore ? 'más' : 'menos'}
    </Link>
  );

  return (
    <>
      <title>Comunidad Spark</title>
      {/* <Grow in={offset < 5 ? false : true}> */}
      <header className={classes.header}>
        <nav className={classes.nav}>
          <div className={classes.landingWrapper}>
            <Logo type="landing" />
            <Button
              id="login"
              className={classes.loginBtn}
              color="primary"
              variant="contained"
              onClick={handleLogin}
            >
              {t('signIn.login')}
            </Button>
          </div>
        </nav>
      </header>
      {/* </Grow> */}
      <Grid className={gridContainer}>
        <Grid item className={headVideoWrapper}>
          <VideoPlayer
            videoId={LANDING_VIDEO_ID.hero}
            index={0}
            autoPlay={autoPlayObject.landing}
          />
        </Grid>
        <Grid item container className={qsContainer}>
          <Grid item className={qsWrapper}>
            <Typography className={qsHead}>
              {LANDING_PAGE_CONTENT.Q1}
            </Typography>
            <Typography className={contentBody}>
              {LANDING_PAGE_CONTENT.A1}
            </Typography>
          </Grid>
          <Grid item className={videoWrapper}>
            <VideoPlayer
              videoId={LANDING_VIDEO_ID.whatCS}
              index={1}
              autoPlay={autoPlayObject.whatCS}
            />
          </Grid>
        </Grid>
        <Grid item container className={qsContainer}>
          {/* {width > 600 && (
            <Grid item className={videoWrapper}>
              <VideoPlayer
                videoId={LANDING_VIDEO_ID.howCS}
                index={2}
                autoPlay={autoPlayObject.howCS}
              />
            </Grid>
          )} */}
          {/* <Grid item className={qsWrapper}>
            <Typography className={qsHead}>
              {LANDING_PAGE_CONTENT.Q2}
            </Typography>
            <Typography className={contentBody}>
              {LANDING_PAGE_CONTENT.A2}
            </Typography>
          </Grid> */}
          {/* {width <= 600 && (
            <Grid item className={videoWrapper}>
              <VideoPlayer
                videoId={LANDING_VIDEO_ID.howCS}
                index={2}
                autoPlay={autoPlayObject.howCS}
              />
            </Grid>
          )} */}
        </Grid>
        {/* <Carousel /> */}
        <Grid item className={faqWrapper}>
          <Typography className={contentHead} style={{ textAlign: 'center' }}>
            Preguntas frecuentes
          </Typography>
          {getFaqContent()}
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          {getSeeMoreLink()}
        </Grid>
        <Grid item>
          <HomeFooter type="landing" />
        </Grid>
      </Grid>
    </>
  );
};

Landing.defaultProps = {
  classes: {}
};

Landing.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Landing);
