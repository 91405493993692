import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { isEmptyString } from '../../../utils/common';
import ApiPath from '../../../constants/api';
import apiCall from '../../../utils/api';
import API, {
  API_STATUS_CODE,
  DOB_MONTH_OPTIONS
} from '../../../constants/common';
import { SELECT_MENU_POPOVER_STYLE } from '../../../constants/styles';
import styles from './styles';

const UpdateDob = ({
  classes,
  newDateOfBirth,
  openDialog,
  setNewDateOfBirth,
  setOpenDialog,
  setIsSnackbarOpen,
  setSnackbarMessage
}) => {
  const title = 'dob-dialog';
  const desc = 'save-date-of-birth';
  const descTitle = 'title';

  const { t } = useTranslation();

  useEffect(() => {
    apiCall(`${ApiPath.profile}`, API.METHOD.GET, {}, false).then(
      (response) => {
        if (response?.error) {
          setIsSnackbarOpen(true);
          setSnackbarMessage(
            `Date of birth fetch error: ${response?.error?.message}`
          );
        }
        if (response?.status === API_STATUS_CODE.SUCCESS) {
          const { screenName, dateOfBirth } = response?.data;
          const dob = dateOfBirth?.split('-');
          setNewDateOfBirth({
            screenName,
            year: dob[0],
            month: dob[1],
            day: dob[2]
          });
        }
      }
    );
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    //api call to save DOB
    const { screenName, year, month, day } = newDateOfBirth;
    const payload = {
      screenName,
      dateOfBirth: `${year}-${month}-${day}`
    };
    apiCall(`${ApiPath.profile}`, API.METHOD.PUT, payload, false).then(
      (response) => {
        if (response?.error) {
          setIsSnackbarOpen(true);
          setSnackbarMessage(
            `Date of birth update error: ${response?.error?.message}`
          );
        }
        if (response?.status === API_STATUS_CODE.SUCCESS) {
          setIsSnackbarOpen(true);
          setSnackbarMessage(t('messages.save_info'));
          handleClose();
        }
      }
    );
  };

  const handleDobChange = (value, type) => {
    setNewDateOfBirth({ ...newDateOfBirth, [type]: value });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={desc}
      disableBackdropClick
      classes={{
        paper: classes.dialog
      }}
    >
      <DialogTitle id={title} classes={{ root: classes.dialogTitle }}>
        {t('messages.welcome_title')}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <DialogContentText id={desc}>
          {t('messages.select_birthday')}
        </DialogContentText>
        <DialogContentText id={descTitle}>{t('common.dob')}</DialogContentText>
        <DialogContentText>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="month-label">{t('profile.month')}</InputLabel>
            <Select
              labelId="month-label"
              id="month"
              value={newDateOfBirth.month}
              defaultValue={DOB_MONTH_OPTIONS.map((option) =>
                option?.id === newDateOfBirth.month ? option?.id : ''
              )}
              onChange={(event) => handleDobChange(event.target.value, 'month')}
              label="Month"
              MenuProps={{
                ...SELECT_MENU_POPOVER_STYLE,
                style: {
                  maxHeight: 256
                }
              }}
            >
              {DOB_MONTH_OPTIONS.map((option) => (
                <MenuItem key={option?.id} value={option?.id}>
                  {option?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="day"
            type="number"
            className={classes.dayField}
            variant="outlined"
            label={t('profile.day')}
            onChange={(event) => handleDobChange(event.target.value, 'day')}
            value={newDateOfBirth.day}
            placeholder="DD"
            maxLength={2}
            error={isEmptyString(newDateOfBirth.day)}
            required
            inputProps={{
              className: classes.input
            }}
            data-testid="day-field"
          />
          <TextField
            id="year"
            type="number"
            className={classes.yearField}
            variant="outlined"
            label={t('profile.day')}
            onChange={(event) => handleDobChange(event.target.value, 'year')}
            value={newDateOfBirth.year}
            placeholder="YYYY"
            maxLength={4}
            error={isEmptyString(newDateOfBirth.year)}
            required
            inputProps={{
              className: classes.input
            }}
            data-testid="year-field"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogOkActions }}>
        <Button
          id="save-dob"
          className={classes.save}
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          {t('link.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UpdateDob.defaultProps = {
  classes: {}
};

UpdateDob.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired
};

export default withStyles(styles)(UpdateDob);
