import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import DataTable from '../DataTable';
import ListNoResult from '../ListNoResult';
import Skeleton from '../Skeleton';
import { useWindowSize } from '../../hooks';
import { isValidArray } from '../../utils/common';
import { DATE_DISPLAY_SLASH_FORMAT } from '../../constants/common';

import styles from './styles';

const List = ({
  classes,
  data,
  fieldMapping,
  handlePageUp,
  handleSort,
  hasNextPage,
  isLoading,
  isError,
  sort,
  SkeletonCount,
  onCellClick,
  clickableFieldId
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const renderTableCell = (item, row, index) => {
    const cellOrder = index % 2 ? classes.oddCell : classes.evenCell;

    switch (item.type) {
      case 'name':
        return (
          <div
            className={`${classes.topCell} ${cellOrder} ${classes.nameWidth}`}
          >
            {row[item.field]}
          </div>
        );
      case 'status':
        return (
          <div
            className={`${classes.bottomCell} 
              ${cellOrder}
            `}
          >
            <span className={classes.status}>status:</span>
            {row[item.field]}
          </div>
        );
      case 'date':
        return (
          <div
            className={`${classes.bottomCell} 
              ${cellOrder}
             ${classes.dateWidth}`}
            data-testid="dateCell"
          >
            {row[item.field] &&
              moment(row[item.field]).format(DATE_DISPLAY_SLASH_FORMAT)}
          </div>
        );
      case 'points':
        return (
          <div className={`${classes.topCell} ${cellOrder}`}>
            {row[item.field]} pts
          </div>
        );

      case 'clickable':
        return <></>;

      default:
        return (
          <div className={`${classes.topCell} ${cellOrder}`}>
            {row[item.field]}
          </div>
        );
    }
  };

  return (
    <>
      {!isLoading && isValidArray(data) && (
        <Grid className={classes.listBottom}>
          {width > 600 ? (
            <DataTable
              fieldMapping={fieldMapping}
              listData={data}
              hasNextPage={hasNextPage}
              handleSort={handleSort}
              handlePageUp={handlePageUp}
              sort={sort}
            />
          ) : (
            <>
              {data.map((row, index) => (
                <Grid
                  key={index}
                  className={classes.wrapper}
                  onClick={() => onCellClick?.(row[`${clickableFieldId}`])}
                >
                  {fieldMapping.map((item, index) =>
                    renderTableCell(item, row, index)
                  )}
                </Grid>
              ))}
              {hasNextPage && (
                <div className={classes.buttonHolder}>
                  <Button
                    id="show-more"
                    variant="outlined"
                    color="primary"
                    onClick={handlePageUp}
                    style={{ fontSize: '14px', textTransform: 'none' }}
                  >
                    {t('link.showMore')}
                  </Button>
                </div>
              )}
            </>
          )}
        </Grid>
      )}
      {isLoading && (
        <div className={classes.skeletonWrapper}>
          <Skeleton count={SkeletonCount} />
        </div>
      )}
      {!isLoading && !isValidArray(data) && <ListNoResult />}
      {!isLoading && isError && (
        <div className="no-results center-align smooth-trans">
          Error in fetching , contact support.
        </div>
      )}
    </>
  );
};

List.defaultProps = {
  classes: {},
  handlePageUp: () => {},
  handleSort: () => {},
  sort: {},
  SkeletonCount: 10
};

List.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array.isRequired,
  fieldMapping: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  handlePageUp: PropTypes.func,
  handleSort: PropTypes.func,
  hasNextPage: PropTypes.bool.isRequired,
  sort: PropTypes.object,
  SkeletonCount: PropTypes.number
};

export default withStyles(styles)(List);
