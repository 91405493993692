import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import Header from '../../components/Header';
import Logo from '../../components/Logo';
import styles from './styles';

const PageNotFound = ({ classes }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSuccess = (event) => {
    navigate('/');
  };

  return (
    <>
      <title>Comunidad Spark: {t('page404.notFound')}</title>
      <Header />
      <div className={classes.root}>
        <p className={classes.header}>{t('page404.notFound')}</p>
        <Logo type="pageNotFound" />
        <p className={classes.content}>{t('page404.content')}</p>
        <div className={classes.buttonHolder}>
          <Button
            id="back-to-home"
            variant="contained"
            className={classes.button}
            color="primary"
            type="submit"
            value="pageNotFound"
            onClick={handleSuccess}
          >
            {t('page404.backToHome')}
          </Button>
        </div>
      </div>
    </>
  );
};

PageNotFound.defaultProps = {
  classes: {}
};

PageNotFound.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(PageNotFound);
