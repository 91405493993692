/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Snackbar } from '@mui/material';
import { withStyles } from '@mui/styles';
import Activity from './Activity';
import Logo from '../Logo';
import Skeleton from '../Skeleton';
import { useWindowSize } from '../../hooks';
import ApiPath from '../../constants/api';
import apiCall from '../../utils/api';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION
} from '../../constants/common';
import styles from './styles';

const Activities = ({ classes }) => {
  const { t } = useTranslation();
  const [activities, setActivities] = useState([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { width } = useWindowSize();
  const getSpace = () => (width <= 600 ? 0 : width <= 960 ? 2 : 3);

  useEffect(() => {
    apiCall(`${ApiPath.availActivities}`, API.METHOD.POST).then(response => {
      if (response?.error) {
        setIsSnackbarOpen(true);
        setSnackbarMessage(response?.error?.message);
        setIsError(true);
      }

      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setActivities(response?.data?.data);
        setIsError(false);
      }

      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        message={snackbarMessage}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={() => setIsSnackbarOpen(false)}
        ContentProps={{
          classes: {
            root: classes.snackbarRoot
          }
        }}
      />
      {!isLoading && activities?.length > 0 && (
        <div className={classes.root}>
          <Grid container justifyContent='center' spacing={getSpace()}>
            {activities?.map((activity, index) => (
              <Grid key={activity?.name} item xs={12} sm={6} md={4}>
                <Activity {...activity} imageType={index} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {!isLoading && activities?.length <= 0 && (
        <div className={classes.noActWrapper}>
          <p>{t('home.noActivities')}</p>
          <Logo type="emptyCart" />
        </div>
      )}
      {isLoading && (
        <div className={classes.skeletonWrapper}>
          <Skeleton count={10} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="no-results center-align smooth-trans">
          Error in fetching , contact support.
        </div>
      )}
    </>
  );
};

Activities.defaultProps = {
  classes: {}
};

Activities.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Activities);
