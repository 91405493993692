import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { NotificationsRounded } from '@mui/icons-material';
import { Card, IconButton, Popover, Typography, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import Logo from '../Logo';
import { getOrigin } from '../../constants/styles';
import styles from './styles';

const Header = ({ classes, type, user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notification, setNotification] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  // const notify = (event) => {
  //   setNotification(event.currentTarget);
  // };

  // const closeNotify = () => {
  //   setNotification(null);
  // };

  // const notifyOpen = Boolean(notification);
  // const notifyId = notifyOpen ? 'notification' : undefined;

  const openUserProfile = (event) => {
    setUserProfile(event.currentTarget);
  };

  const closeUserProfile = () => {
    setUserProfile(null);
  };

  const userOpen = Boolean(userProfile);
  const userId = userOpen ? 'user-detail' : undefined;

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    navigate('/home', { replace: true, state: '' });
    navigate('/login', { state: 'logOff' });
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const handleHomeClick = () => {
    navigate('/home', { state: 'homeLogoEvent' });
  };

  return (
    <header>
      <nav className={classes.nav}>
        <Logo callBack={handleHomeClick} />
        {user && (
          <div className={classes.optionHolder}>
            {/* <IconButton
              onClick={notify}
              id={notifyId}
              aria-label="notify"
              data-testid={'notify'}
            >
              <NotificationsRounded className={classes.notifyIcon} />
            </IconButton> */}
            <IconButton
              onClick={openUserProfile}
              id={userId}
              aria-label="profile"
              data-testid={'profile'}
            >
              <div className={classes.userName}>
                {user && user?.charAt(0).toUpperCase()}
              </div>
            </IconButton>
            {/* <Popover
              id={notifyId}
              open={notifyOpen}
              anchorEl={notification}
              onClose={closeNotify}
              anchorOrigin={getOrigin('bottom', 'left')}
              transformOrigin={getOrigin('top', 'center')}
              data-testid={'notify-pop'}
            >
              <Typography className={classes.typography}>
                t{'header.noNotify'}
              </Typography>
            </Popover> */}
            <Popover
              id={userId}
              open={userOpen}
              anchorEl={userProfile}
              onClose={closeUserProfile}
              anchorOrigin={getOrigin('bottom', 'left')}
              transformOrigin={getOrigin('top', 'center')}
              data-testid={'profile-pop'}
            >
              <Card
                className={`${classes.typography} ${classes.center} ${classes.card}`}
                role="dialog"
              >
                <Button
                  id="profile"
                  className={classes.btnStyle}
                  onClick={handleProfile}
                  data-testid={'profile'}
                >
                  {t('header.viewProfile')}
                </Button>
                <Button
                  id="logout"
                  className={classes.btnStyle}
                  onClick={logout}
                  data-testid={'logout'}
                >
                  {t('header.logout')}
                </Button>
              </Card>
            </Popover>
          </div>
        )}
      </nav>
    </header>
  );
};

Header.defaultProps = {
  classes: {},
  type: ''
};

Header.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  user: PropTypes.object
};

export default withStyles(styles)(Header);
