import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { withStyles } from '@mui/styles';
import apiCall from '../../../utils/api';
import ApiPath from '../../../constants/api';
import API, { API_STATUS_CODE } from '../../../constants/common';
import { unsubContent } from '../../../constants/common';
import styles from './styles';

const Unsubscribe = ({ classes, openDialog, setOpenDialog }) => {
  const title = 'unsubscribe-dialog';
  const desc = 'confirm-or-cancel-unsubscribe';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [unsubscribe, setUnsubscribe] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    navigate('/profile', { state: 'unsubCancel' });
  };

  const handleConfirm = () => {
    apiCall(ApiPath.unsubscribe, API.METHOD.POST, {}, false).then(
      (response) => {
        if (response?.error) {
          console.log(response?.error?.message);
        }

        if (response?.status === API_STATUS_CODE.SUCCESS) {
          setUnsubscribe(true);
        }
      }
    );
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    navigate('/home', { replace: true, state: '' });
    navigate('/login', { state: 'logOff' });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={desc}
      disableEscapeKeyDown
      classes={{
        paper: classes.dialog
      }}
    >
      {unsubscribe ? (
        <>
          <DialogTitle />
          <DialogContent classes={{ root: classes.dialogOkContent }}>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              We’re sorry to see you go! Please give us 72 hours to process your
              request to unsubscribe.
            </DialogContentText>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id={title} classes={{ root: classes.dialogTitle }}>
            {t('unsubDialog.header')}
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              {t('unsubDialog.subHeader')}
            </DialogContentText>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              {t('unsubDialog.note')}
            </DialogContentText>
            {/* <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              {unsubContent.TEXT_3}
            </DialogContentText> */}
          </DialogContent>
        </>
      )}
      {unsubscribe ? (
        <DialogActions classes={{ root: classes.dialogOkActions }}>
          <Button
            id="alert-ok"
            className={classes.ok}
            onClick={handleLogout}
            color="primary"
            variant="contained"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      ) : (
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            id="alert-cancel"
            className={classes.confirm}
            onClick={handleClose}
            color="primary"
          >
            {t('link.cancel')}
          </Button>
          <Button
            id="alert-confirm"
            className={classes.confirm}
            onClick={handleConfirm}
            color="primary"
            variant="contained"
          >
            {t('link.confirm')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

Unsubscribe.defaultProps = {
  classes: {}
};

Unsubscribe.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired
};

export default withStyles(styles)(Unsubscribe);
