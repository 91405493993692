import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import Vimeo from '@u-wave/react-vimeo';
import { Replay, PlayCircleFilled, PauseCircleFilled } from '@mui/icons-material';
import styles from './styles';

const VideoPlayer = memo(({ classes, videoId, index, autoPlay }) => {
  const [startedOnce, setStartedOnce] = useState(false);
  const [pauseVideo, setPauseVideo] = useState(true);
  const [endedVideo, setEndedVideo] = useState(false);
  const [isError, setError] = useState(false);
  const [isReady, setReady] = useState(false);

  const errorImageFallback = [
    {
      alt: 'video not found',
      url: '/assets/images/comunidad-spark-hero.jpg'
    },
    {
      alt: 'video not found',
      url: '/assets/images/page-not-found/page-not-found-360.jpg',
      height: 200
    },
    {
      alt: 'video not found',
      url: '/assets/images/list-no-results/no-results-found-360.jpg',
      height: 200
    }
  ];

  const onError = () => {
    setError(true);
  };


  const handleButtonClick = (evt) => {
    evt.stopPropagation();
    setStartedOnce(true);
    if (endedVideo)
      setEndedVideo(false);
    setPauseVideo(!pauseVideo);
  };

  const handleKeyDownPress = (evt) => {
    if (evt.code !== 'Tab') {
      handleButtonClick(evt);
    }
  };

  const getErrorIcon = () => (
    <img
      src={errorImageFallback?.[index]?.url}
      alt={errorImageFallback?.[index]?.alt}
      height={errorImageFallback?.[index]?.height}
    />
  );

  const getIcon = (icon) => {
    switch (icon) {
      case 'play':
        return <PlayCircleFilled className={classes.replayButton} />;
      case 'pause':
        return <PauseCircleFilled className={classes.pauseButton} />;
      case 'replay':
        return <Replay className={classes.replayButton} />;
      default:
        return;
    }
  };

  const getIconBtnContainer = (icon) => (
    <div className={classes.btnContainer}>
      <IconButton
        id="playVideoBtn"
        className={classes.paddingTop}
        onClick={(evt) => handleButtonClick(evt)}
        onKeyDown={(evt) => handleKeyDownPress(evt)}
        aria-label="Play Video"
      >
        {getIcon(icon)}
      </IconButton>
    </div>
  );

  useEffect(() => {
    setPauseVideo(!autoPlay);
    setStartedOnce(autoPlay);
  }, [isReady, autoPlay]);

  const startOnReady = () => {
    setReady(true);
  };

  const onEndPlayer = () => {
    setPauseVideo(true);
    setEndedVideo(true)
    if (!startedOnce) {
      setStartedOnce(true);
    }
  };

  const getPlayButton = () => {
    if (startedOnce) {
      if (pauseVideo && endedVideo) {
        return getIconBtnContainer('replay');
      } else if (pauseVideo && !endedVideo) {
        return getIconBtnContainer('play');
      } else if (!pauseVideo && !endedVideo) {
        return getIconBtnContainer('pause');
      }
    } else {
      return getIconBtnContainer('play');
    }
  }

  return (
    <div className={clsx(classes.container)}>
      <Vimeo
        className={`${isError ? '' : classes.videoContainer}`}
        controls={false}
        responsive={true}
        onEnd={onEndPlayer}
        onReady={startOnReady}
        onError={onError}
        paused={pauseVideo}
        muted={true}
        autopause={false}
        video={`https://vimeo.com/${videoId}`}
      />
      {isError ? (
        getErrorIcon()
      ) : (
        getPlayButton()
      )}
    </div>
  );
});

VideoPlayer.defaultProps = {
  classes: {}
};

VideoPlayer.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(VideoPlayer);
