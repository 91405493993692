const styles = (theme) => ({
  skeleton: {
    margin: 'auto',
    backgroundImage: 'linear-gradient(#f0f0f0 100%, transparent 0)',
    backgroundRepeat: 'repeat-y',
    borderRadius: 5,
    backgroundPosition: ' 0 0',
    animation: 'shine 1s infinite'
  },

  block: {
    height: 40,
    margin: '10px 5%'
  },

  '@keyframes shine': {
    from: { opacity: 0 },
    to: { opacity: 0.7 }
  }
});

export default styles;
