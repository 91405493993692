import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Radio,
  Typography
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';

import styles from './styles';
import { AVATARS } from '../../constants/common';

const UpdateAvatar = ({ classes, openDialog, setOpenDialog, level, profileUpdateHandler }) => {
  const title = 'avatar-update-dialog';
  const desc = 'choose-avatar';
  const { t } = useTranslation();
  const [selectedAvatar, setSelectedAvatar] = useState(0);

  const handleAvatarSelect = (event) => {
    setSelectedAvatar(parseInt(event.target.value));
  };

  const handleClose = () => {
    setOpenDialog(false);
  }

  const handleSubmit = () => {
    profileUpdateHandler(selectedAvatar);
    setOpenDialog(false);
  }

  const avatarRadioButtonProps = (avatarId) => ({
    checked: selectedAvatar === avatarId,
    onChange: handleAvatarSelect,
    value: avatarId,
    name: 'avatar-radio-button',
    inputProps: { 'aria-label': `avatar-${avatarId}` },
  });

  const AvatarPicker = ({ avatarId, active, imgPath }) => {
    const imageCssClass = classes.avatarPickerImage + ' ' + (active ? '' : classes.greyImage);
    return (
      <Grid item xs={30} sm={20} md={15} lg={12} className={classes.avatarPickerContainer}>
        <img src={imgPath} className={imageCssClass} alt='avatar-options' />
        {active ?
          <Radio {...avatarRadioButtonProps(avatarId)} />
          :
          <LockOutlinedIcon className={classes.lockedIcon} />}
      </Grid>
    )
  }

  const closeButton = (
    <DialogTitle className={classes.closeButtonContainer}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );

  const actionButtons = (
    <div className={classes.actionButtonContainer}>
      <Button variant='text'
        color='primary'
        className={classes.actionButton}
        onClick={handleClose}>
        {t('link.cancel')}
      </Button>
      <Button variant='outlined'
        color='primary'
        className={classes.actionButton}
        onClick={handleSubmit}
        disabled={selectedAvatar == 0}>
        {t('link.save')}
      </Button>
    </div>
  );

  const AvatarGrid = ({ active }) => {
    return (
      <Grid container columns={60}>
        {/* { 
          AVATARS.map((avatar) => {
            if (active && level >= avatar.level)
              return (
                <AvatarPicker avatarId={avatar.id} active={true} key={avatar.id} imgPath={avatar.path} />
              );
            if (!active && level < avatar.level) {
              return (
                <AvatarPicker avatarId={avatar.id} active={false} key={avatar.id} imgPath={avatar.path} />
              );
            }
          })
        } */}
        {
          AVATARS.map((avatar) => 
            <AvatarPicker avatarId={avatar.id} active={true} key={avatar.id} imgPath={avatar.path} />
          )
        }
      </Grid>
    );
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={desc}
      disableEscapeKeyDown
      scroll="paper"
      maxWidth="lg"
      fullWidth={true}
      classes={{
        paper: classes.dialog
      }}
      data-testid={title}
    >
      {closeButton}
      <div className={classes.dialogHeader}>
        <Typography variant={'h5'} component={'h2'}>{t('editAvatarDialog.myAvatars')}</Typography>
        <Typography variant={'subtitle1'}>{t('editAvatarDialog.selectAvatar')}</Typography>
        {actionButtons}
      </div>
      <DialogContent>
        <AvatarGrid active={true} />
        {/* {level < AVATARS[AVATARS.length - 1].level && (
          <>
            <Typography
              variant={'subtitle1'}
              className={classes.completeMoreActivitiesSubtitle}>
              {t('editAvatarDialog.unlockAvatars')}
            </Typography>
            <AvatarGrid active={false} />
          </>
        )} */}
      </DialogContent>
    </Dialog>
  );
};

UpdateAvatar.defaultProps = {
  classes: {}
};

UpdateAvatar.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired
};

export default withStyles(styles)(UpdateAvatar);
