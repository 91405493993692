const styles = (theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '&:hover .MuiButtonBase-root .MuiSvgIcon-root': {
      visibility: 'visible'
    }
  },
  videoContainer: {
    width: '100% !important',
    '& .video-js .vjs-dock-text': {
      display: 'contents !important'
    },
    '& .video-js .vjs-big-play-button:hover': {
      backgroundColor: '#0071CE !important'
    },
    '& .video-js:hover .vjs-big-play-button': {
      backgroundColor: '#0071CE !important'
    }
  },
  bgCalcite: {
    backgroundColor: '#ffffff !important',
    opacity: '0.5 !important'
  },
  replayButton: {
    color: '#0071CE',
    width: '2em !important',
    height: '2em !important',
  },
  pauseButton: {
    color: '#ccc',
    width: '2em !important',
    height: '2em !important',
    visibility: 'hidden'
  },
  btnContainer: {
    width: '100% !important',
    position: 'absolute !important',
    top: '40% !important',
    left: '43% !important',
    [theme.breakpoints.up('sm')]: {
      left: '46% !important'
    },
    [theme.breakpoints.up('md')]: {
      left: '47% !important'
    },
    [theme.breakpoints.up('md')]: {
      left: '48% !important'
    }
  }
});

export default styles;
