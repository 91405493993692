const themeStyles = {
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        contained: { marginLeft: 0 }
      }
    },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          height: 68
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        adornedStart: {
          paddingLeft: '3px'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#0071ce'
    },
    text: {
      primary: '#333333',
      secondary: '#6d6e71'
    }
  },
  typography: {
    fontFamily: 'Myriad Pro'
  }
};

export default themeStyles;

export const getOrigin = (vertical, horizontal) => ({ vertical, horizontal });

export const TAB_INDICATOR_STYLE = {
  style: {
    borderRadius: '100px 100px 0px 0px',
    height: 3
  }
};

export const SELECT_MENU_POPOVER_STYLE = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

export const CONFETTI_STYLE = {
  angle: 90,
  spread: 100,
  startVelocity: 30,
  elementCount: 200,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 2,
  width: '10px',
  height: '10px',
  colors: [
    '#a864fd',
    '#29cdff',
    '#78ff44',
    '#ff718d',
    '#fdff6a',
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548'
  ]
};
