import axios from 'axios';
import _ from 'lodash';
import xss from 'xss';
import configuration from '../config/config';
import api from '../constants/api';
import API from '../constants/common';
import { getCookie } from "./common";
import { getIamAuthToken } from "./jwt";

const refreshTokenCall = _.debounce(error => {
  const originalRequest = error?.config;

  if (originalRequest.url === api.refreshToken) {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login';

    return Promise.reject(error);
  }

  if (
    error?.response?.status === 401 &&
    !originalRequest._retry &&
    !originalRequest.skipAuthRefresh
  ) {
    originalRequest._retry = true;

    return axiosInstance({
      url: api.refreshToken,
      method: 'POST',
      headers: { [API.HEADER.AUTH_TOKEN]: xss(localStorage.getItem('guesttoken')) },
      data: { refreshToken: localStorage.getItem('refreshToken') },
      skipAuthRefresh: true
    }).then(tokenRefreshResponse => {
      const sanitizedtoken = xss(tokenRefreshResponse.data.authToken);

      localStorage.setItem('token', sanitizedtoken);

      return axiosInstance(originalRequest);
    });
  }
}, 300);

const axiosInstance = axios.create({});

const apiCall = (
  url,
  method = API.METHOD.GET,
  payload = {},
  skipAuthRefresh = false
) => {
  try {
    const options = {
      url,
      method,
      skipAuthRefresh
    };

    if (method === 'POST' || method === 'PUT') {
      options.data = payload;
    }

    return axiosInstance(options)
      .then(response => response)
      .catch(error => ({ error: error?.response?.data }));
  } catch (error) {
    return error;
  }
};

axiosInstance.interceptors.request.use(
  config => {
    const serviceHeader = {
      'WM_CONSUMER.ID': configuration.consumerId
    };

    if (config.skipAuthRefresh) {
      serviceHeader['WM_SEC.AUTH_TOKEN'] = getCookie('guesttoken');
    } else {
      serviceHeader.authorization = `Bearer ${localStorage.getItem('token')}`;
      serviceHeader['WM_SEC.AUTH_TOKEN'] = getIamAuthToken(
        localStorage.getItem('token')
      );
    }

    config.headers = { ...API.DEFAULT_HEADER, ...serviceHeader };

    return Promise.resolve(config);
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    refreshTokenCall(error);

    return Promise.reject(error);
  }
);

export default apiCall;
