const styles = (theme) => ({
  root: {
    minWidth: 328,
    marginTop: 32,
    borderRadius: 8,
    [theme.breakpoints.up('sm')]: {
      minWidth: 'auto',
      marginTop: 16
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 8
    }
  },
  action: {
    fontSize: 18,
    letterSpacing: 0.32,
    color: '#0071ce'
  },
  actionRoot: {
    padding: 0
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 103,
    paddingLeft: 9,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 11
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 7
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 16
    }
  },
  imgWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '30.5px 9px 0 0',
    [theme.breakpoints.up('sm')]: {
      margin: '32px 10px 0 0'
    },
    [theme.breakpoints.up('md')]: {
      margin: '28px 7px 0 0'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '51px 24px 0 0'
    }
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderTop: 'solid 1px #e6e6e6',
    height: 44,
    marginTop: 30,
    '&:hover': {
      backgroundColor: '#e5f0fa'
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 35,
      height: 49
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 29,
      height: 43
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 69,
      height: 60
    }
  },
  points: {
    marginTop: 19,
    fontSize: 24,
    fontWeight: 300,
    letterSpacing: 0.43,
    color: '#333333',
    [theme.breakpoints.up('sm')]: {
      marginTop: 25
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 16
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 24
    }
  },
  title: {
    minWidth: 147,
    minHeight: 50,
    marginTop: 16,
    fontSize: 18,
    letterSpacing: 0.32,
    color: '#333333',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 8,
    [theme.breakpoints.up('sm')]: {
      minWidth: 161,
      marginTop: 20
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 147,
      marginTop: 13
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 202,
      marginTop: 16
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  }
});

export default styles;
