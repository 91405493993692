import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@mui/material';
import { withStyles } from '@mui/styles';
import { isValidArray } from '../../utils/common';
import { TAB_INDICATOR_STYLE } from '../../constants/styles';
import styles from './styles';

const SuperTab = ({ classes, changeTab, resetInnerTab, tabItems }) => {
  const { selected, tab, tabContainer, wrapper } = classes;
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (tabIndex) => {
    setCurrentTab(tabIndex);
    changeTab(tabItems[tabIndex].value);
    resetInnerTab(true);
  };

  useEffect(() => {
    setCurrentTab(0);
  }, []);

  return (
    <div className={wrapper}>
      <div className={tabContainer}>
        {isValidArray(tabItems) && (
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            onChange={(event, value) => handleTabChange(value)}
            TabIndicatorProps={TAB_INDICATOR_STYLE}
          >
            {tabItems.map((item, index) => (
              <Tab
                key={index}
                className={tab}
                classes={{ selected: selected }}
                label={item.display}
                data-testid={`${item.value}-tab`}
              />
            ))}
          </Tabs>
        )}
      </div>
    </div>
  );
};

SuperTab.defaultProps = {
  classes: {}
};

SuperTab.propTypes = {
  classes: PropTypes.object,
  changeTab: PropTypes.func.isRequired,
  tabItems: PropTypes.array.isRequired,
  resetInnerTab: PropTypes.func.isRequired
};

export default withStyles(styles)(SuperTab);
